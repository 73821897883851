import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { deleteReport, sendFile, sendForm, sendPictures } from "../Api";
import { CancelButton } from "../components/CancelButton";
import DropZone from "../components/DropZone";
import Inputfield from "../components/Inputfield";
import { Kvittering } from "../components/Kvittering";
import { ProceedButton } from "../components/ProceedButton";
import { HeaderPadding } from "../styles/styles";
import {
  placeholder_oppdragsnummer,
  placeholder_stedsnavn,
} from "../types/placeholders";
import {
  boligTekstFormWithOutFiles,
  ReportStatus,
  uploadResponse,
} from "../types/types";
import { colors } from "../utils/colors";
import { getBasePath } from "../utils/env";
import { validateText } from "../utils/form_validator";
import UserContext, { AccessControl } from "../utils/UserContext";

export const changeStateString = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setFunc: (value: string) => void
) => {
  setFunc(event.target.value);
};

export enum FlowStateScreen {
  Personalia = -1,
  BuildingInfo,
  Attachments,
  Summary,
  Generating,
  DownloadReport,
  FailedToGenerate,
}

interface FormStatus {
  numberOfPictures: number;
  numberOfPicturesUploaded: number;
  pictureErrorStatus?: number;
  formheader?: string;
  progressbar?: string;
  step: FlowStateScreen;
}

const OrderForm = () => {
  const { userSession } = useContext(UserContext);
  const history = useHistory();

  const imageTypesSupported = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/zip",
  ];
  const rapportTypesSupported = ["application/pdf"];

  const [formStatus, setFormStatus] = useState<FormStatus>({
    numberOfPictures: 0,
    numberOfPicturesUploaded: 0,
    pictureErrorStatus: undefined,
    formheader: "Informasjon",
    progressbar: undefined,
    step: 0,
  });

  const [formData, setFormData] = useState<boligTekstFormWithOutFiles>({
    name: userSession.user?.name || "",
    userId: userSession.user?.id || "",
    oppdragsnummer: "",
    lokaltStedsnavn: "",
    telefon: userSession.user?.phone || "",
    epost: userSession.user?.email || "",
    orgnrMeglerselskap: userSession.user?.office?.orgNummer || "",
    status: ReportStatus.NEW,
  });

  const setNumberOfPicturesUploaded = useCallback((value: number) => {
    setFormStatus((prev) => ({
      ...prev,
      numberOfPicturesUploaded: value,
    }));
  }, []);

  const setPictureErrorStatus = useCallback((value?: number) => {
    setFormStatus((prev) => ({
      ...prev,
      pictureErrorStatus: value,
    }));
  }, []);

  const [tilstandsrapport, setTilstandsrapport] = useState<File>();

  const [tilstandsrapport_ID, setTailstandsrapport_ID] =
    useState<uploadResponse>();

  const [egenerklaering, setEgenerklaering] = useState<File>();

  const [egenerklaering_ID, setEgenerklaering_ID] = useState<uploadResponse>();

  const [pictures, setPictures] = useState<File[]>([]);

  const [rapport_id, setRapport_id] = useState<string>();

  const [downloadLink, setDownloadLink] = useState("");

  const [errorTextStepOne, seterrorTextStepOne] = useState({
    username: "",
    telefon: "",
    e_post: "",
    org_nr_meglerkontor: "",
    oppdragsnummer: "",
    lokaltStedsnavn: "",
  });

  const [errorTextStepThree, seterrorTextStepThree] = useState({
    tilstandsrapport: "",
  });

  const [errors, setErrors] = useState({
    username: false,
    telefon: false,
    e_post: false,
    org_nr_meglerkontor: false,
    oppdragsnummer: false,
    adresse: false,
    stedsnavn: false,
    prisklasse: false,
    pictures: false,
  });

  const refreshUserInfo = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      name: userSession.user?.name || "",
      telefon: userSession.user?.phone || "",
      epost: userSession.user?.email || "",
      orgnrMeglerselskap: userSession.user?.office?.orgNummer || "",
    }));
  }, [userSession.user]);

  useEffect(() => {
    switch (formStatus.step) {
      case -1:
        setFormStatus((prev) => ({
          ...prev,
          formheader: "...laster",
          progressbar: undefined,
        }));
        break;
      case 0:
        setFormStatus((prev) => ({
          ...prev,
          formheader: "Bestilling",
          progressbar: "/images/progress/informasjonOmBolig.svg",
        }));
        break;
      case 1:
        setFormStatus((prev) => ({
          ...prev,
          formheader: "Bestilling",
          progressbar: "/images/progress/informasjonOmBolig.svg",
        }));
        break;
      case 2:
        setFormStatus((prev) => ({
          ...prev,
          formheader: "Oppsummering",
          progressbar: "/images/progress/oppsumering.svg",
        }));
        break;
      default:
        setFormStatus((prev) => ({
          ...prev,
          formheader: undefined,
          progressbar: undefined,
        }));
    }
  }, [formData, formStatus.step]);

  useEffect(() => {
    if (formStatus.step !== 0 && !rapport_id) {
      setFormData((prev) => ({ ...prev, step: 0 }));
    }
  }, [formStatus.step, rapport_id]);

  const fieldsAreValid = () => {
    return !(formData.oppdragsnummer === "" || formData.lokaltStedsnavn === "");
  };

  useEffect(() => {
    async function sendData() {
      setEgenerklaering_ID(
        await sendFile(
          getBasePath() + `/api/v1/rapporter/${rapport_id}/egenerklaering`,
          egenerklaering!!
        )
      );
    }
    if (egenerklaering) {
      sendData();
    }
  }, [egenerklaering, rapport_id]);

  useEffect(() => {
    async function sendData() {
      setTailstandsrapport_ID(
        await sendFile(
          getBasePath() + `/api/v1/rapporter/${rapport_id}/tilstandsrapport`,
          tilstandsrapport!!
        ).then((res) => {
          if (res?.id) {
            seterrorTextStepThree((prev) => ({
              ...prev,
              tilstandsrapport:
                parseInt(res.id) === -1
                  ? "OBS: Denne rapport-typen støttes ikke. "
                  : "",
            }));
          }
          return res;
        })
      );
    }
    if (tilstandsrapport) {
      sendData();
    }
  }, [rapport_id, tilstandsrapport]);

  useEffect(() => {
    if (pictures.length !== 0)
      sendPictures(
        pictures,
        rapport_id,
        setNumberOfPicturesUploaded,
        setPictureErrorStatus
      );
  }, [
    pictures,
    rapport_id,
    setNumberOfPicturesUploaded,
    setPictureErrorStatus,
  ]);

  useEffect(() => {
    if (formStatus.pictureErrorStatus) {
      setErrors(() => ({
        ...errors,
        pictures: true,
      }));
    }
  }, [formStatus.pictureErrorStatus, errors]);

  useEffect(() => {
    if (downloadLink && formStatus.step === 3) {
      setFormStatus((prev) => ({
        ...prev,
        step: formStatus.step + 1,
      }));
    }
  }, [downloadLink, formStatus.step]);

  useEffect(() => {
    if (
      userSession.loaded &&
      userSession.user?.name !== "" &&
      formStatus.step === -1
    ) {
      let tempFormData = {
        name: userSession.user?.name || "",
        userId: userSession.user?.id || "",
        oppdragsnummer: "",
        lokaltStedsnavn: "",
        telefon: userSession.user?.phone || "",
        epost: userSession.user?.email || "",
        orgnrMeglerselskap: userSession.user?.office?.orgNummer || "",
        status: ReportStatus.NEW,
      };
      setFormData(tempFormData);
    }
  }, [formData, userSession, formStatus.step]);

  useEffect(() => {
    if (userSession.loaded) {
      refreshUserInfo();
    }
  }, [userSession.loaded, refreshUserInfo]);

  // deletes report when user exits page using URL
  useEffect(() => {
    const alertUser = (e: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      if (!rapport_id) {
        return;
      }

      if (formStatus.step > 2) {
        return;
      }

      e.preventDefault();
      e.returnValue = "";

      if (rapport_id) {
        deleteReport(rapport_id);
        setRapport_id(undefined);
        setFormStatus((prev) => ({ ...prev, step: 0 }));
        let tempFormData = {
          name: userSession.user?.name || "",
          userId: userSession.user?.id || "",
          oppdragsnummer: "",
          lokaltStedsnavn: "",
          telefon: userSession.user?.phone || "",
          epost: userSession.user?.email || "",
          orgnrMeglerselskap: userSession.user?.office?.orgNummer || "",
          status: ReportStatus.NEW,
        };
        setFormData(tempFormData);
      }
    };

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  });

  // deletes report when user navigates to a different page on site

  useEffect(() => {
    const unListen = history.listen(() => {
      if (rapport_id && formStatus.step < 3 && !downloadLink) {
        deleteReport(rapport_id);
        setRapport_id(undefined);
      }
    });
    return () => {
      unListen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  AccessControl(userSession);

  const step1completed = () => {
    var validTextFields = fieldsAreValid();

    var imagesSubmittedAndUploaded =
      formStatus.numberOfPicturesUploaded !== 0 &&
      formStatus.numberOfPicturesUploaded === formStatus.numberOfPictures;

    var egenerklæringUploaded = egenerklaering_ID !== undefined;

    var tilstandsrapportUploaded =
      tilstandsrapport_ID !== undefined && tilstandsrapport_ID.id !== "-1";

    var stepIsValid =
      validTextFields &&
      imagesSubmittedAndUploaded &&
      egenerklæringUploaded &&
      tilstandsrapportUploaded;

    return stepIsValid;
  };

  return (
    <Wrapper>
      <HeaderPadding />
      <h1>{formStatus.formheader}</h1>
      {(() => {
        switch (formStatus.step) {
          case 0:
            return (
              <Container>
                <Form onSubmit={(event) => event.preventDefault()}>
                  <FlexContainer>
                    <Inputfield
                      title="OPPDRAGSNUMMER:"
                      type="text"
                      value={formData.oppdragsnummer}
                      onChangeInputElement={(event) => {
                        if (
                          event.target.value.replace(/\s/g, "").length <= 255
                        ) {
                          setFormData((prev) => ({
                            ...prev,
                            oppdragsnummer: event.target.value,
                          }));
                          const validation = validateText(
                            event.target.value,
                            true
                          );
                          seterrorTextStepOne((prevState) => ({
                            ...prevState,
                            oppdragsnummer: validation || "",
                          }));
                        }
                      }}
                      placeholder={placeholder_oppdragsnummer}
                      errorText={errorTextStepOne.oppdragsnummer}
                    />
                    <Inputfield
                      title="LOKALT STEDSNAVN:"
                      type="text"
                      value={formData.lokaltStedsnavn}
                      onChangeInputElement={(event) => {
                        if (
                          event.target.value.replace(/\s/g, "").length <= 255
                        ) {
                          setFormData((prev) => ({
                            ...prev,
                            lokaltStedsnavn: event.target.value,
                          }));
                          const validation = validateText(event.target.value);
                          seterrorTextStepOne((prevState) => ({
                            ...prevState,
                            lokaltStedsnavn: validation || "",
                          }));
                        }
                      }}
                      placeholder={placeholder_stedsnavn}
                      errorText={errorTextStepOne.lokaltStedsnavn}
                    />
                  </FlexContainer>
                  <ProceedButton
                    step={formStatus.step}
                    updateStep={(value) => {
                      setFormStatus((prev) => ({
                        ...prev,
                        step: value,
                      }));
                    }}
                    sendForm={{
                      info: formData,
                      updateRapportID: setRapport_id,
                      sendForm: sendForm,
                    }}
                    valid={fieldsAreValid()}
                  />
                </Form>
              </Container>
            );
          case 1:
            return (
              <Container>
                <Form>
                  <FlexContainer>
                    <Inputfield
                      title="OPPDRAGSNUMMER:"
                      type="text"
                      value={formData.oppdragsnummer}
                      onChangeInputElement={() => {}}
                      placeholder={placeholder_oppdragsnummer}
                      errorText={errorTextStepOne.oppdragsnummer}
                      isProtected={true}
                    />
                    <Inputfield
                      title="LOKALT STEDSNAVN:"
                      type="text"
                      value={formData.lokaltStedsnavn}
                      onChangeInputElement={() => {}}
                      placeholder={placeholder_oppdragsnummer}
                      errorText={errorTextStepOne.oppdragsnummer}
                      isProtected={true}
                    />
                  </FlexContainer>
                  <DropZoneContainer>
                    <DropZone
                      pathAfterPublic={"/images/dropZones/Bilder_vedlegg.svg"}
                      selectedFiles={pictures}
                      setSelectedFiles={setPictures}
                      multiple={true}
                      validTypes={imageTypesSupported}
                      title="Opplastede bilder"
                      setNumberOfPictures={(value) => {
                        setFormStatus({
                          ...formStatus,
                          numberOfPictures: value,
                        });
                      }}
                      numberOfPictures={formStatus.numberOfPictures}
                      numberOfPicturesUploaded={
                        formStatus.numberOfPicturesUploaded
                      }
                    />
                    <DropZone
                      pathAfterPublic={
                        "/images/dropZones/EgenerklaeringVedlegg.svg"
                      }
                      selectedFiles={egenerklaering}
                      setSelectedFile={setEgenerklaering}
                      validTypes={rapportTypesSupported}
                      title="Egenerklæring"
                      fileUploaded={
                        egenerklaering_ID?.id
                          ? parseInt(egenerklaering_ID?.id)
                          : 0
                      }
                    />
                    <DropZone
                      pathAfterPublic={
                        "/images/dropZones/Tilstandsrapport_vedlegg.svg"
                      }
                      selectedFiles={tilstandsrapport}
                      setSelectedFile={setTilstandsrapport}
                      validTypes={rapportTypesSupported}
                      title="Tilstandsrapport"
                      fileUploaded={
                        tilstandsrapport_ID?.id
                          ? parseInt(tilstandsrapport_ID?.id) > 0
                            ? parseInt(tilstandsrapport_ID?.id)
                            : 0
                          : 0
                      }
                      errorText={errorTextStepThree.tilstandsrapport}
                    />
                  </DropZoneContainer>
                  ;
                  <FlexContainer>
                    <CancelButton
                      onClick={() => {
                        if (rapport_id) {
                          deleteReport(rapport_id);
                          history.push("/");
                        }
                      }}
                    />
                    <ProceedButton
                      step={formStatus.step}
                      updateStep={(value) => {
                        setFormStatus((prev) => ({
                          ...prev,
                          step: value,
                        }));
                      }}
                      valid={step1completed()}
                    />
                  </FlexContainer>
                </Form>
              </Container>
            );
          case 2:
            return (
              <Container>
                <Kvittering
                  infoNotFiles={formData}
                  tilstandsrapportName={tilstandsrapport!!.name}
                  egenerklaeringName={egenerklaering!!.name}
                  pictures={Array.from(pictures!!).map((it) => it.name)}
                />
                <FlexContainer>
                  <CancelButton
                    onClick={() => {
                      if (rapport_id) {
                        deleteReport(rapport_id);
                        history.push("/");
                      }
                    }}
                  />
                  <ProceedButton
                    step={formStatus.step}
                    updateStep={(value) => {
                      setFormStatus((prev) => ({
                        ...prev,
                        step: value,
                      }));
                    }}
                    submit={{
                      setDownloadLink: setDownloadLink,
                      rapportID: rapport_id,
                    }}
                  />
                </FlexContainer>
              </Container>
            );
          case 3:
            return (
              <div>
                <h1
                  css={css`
                    padding-top: 10%;
                    margin-bottom: 0;
                  `}
                >
                  Tekst genereres…
                </h1>
                <img
                  src={`${process.env.PUBLIC_URL}/images/coffee.gif`}
                  alt="Coffee-cup"
                />
              </div>
            );
          case 4:
            return (
              <div>
                <h1
                  css={css`
                    padding: 10%;
                  `}
                >
                  Din rapport er ferdig
                </h1>
                <DownloadButton href={downloadLink}>
                  Last ned rapport
                </DownloadButton>
              </div>
            );
          case FlowStateScreen.FailedToGenerate:
            return (
              <div>
                <h1
                  css={css`
                    padding: 10%;
                  `}
                >
                  Vi klarte dessverre ikke å generere rapporten for deg.
                </h1>
              </div>
            );
          default:
            return "done";
        }
      })()}
    </Wrapper>
  );
  //end
};

export default OrderForm;

const Wrapper = styled.div`
  min-height: 100vh;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
  }
  width: 100%;
`;

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 395px;
  width: 60%;
  min-width: 500px;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 1000px) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
`;

const FlexContainer = styled.div`
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;

  > * + * {
    margin-left: 16px;
  }

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 1000px) {
    width: 90%;
    flex-direction: column;

    > * + * {
      margin-left: 0;
    }
  }
`;

const DownloadButton = styled.a`
  font-size: 20px;
  background-color: ${colors.sekundaer900};
  padding: 8px 16px;
  color: white;
  width: 300px;
  font-family: "Mulish";
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 64px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
