/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect, useState } from "react";
import { generateReport } from "../Api";
import { FlowStateScreen } from "../pages/OrderForm";
import { updateStepType } from "../types/types";
import { colors } from "../utils/colors";
import { getBasePath } from "../utils/env";
import OrderConsent from "./OrderConsent";

export const ProceedButton: FC<updateStepType> = ({
  step,
  updateStep,
  valid = true,
  sendForm,
  submit,
}) => {
  const [show, setShow] = useState(false);
  const [proceed, setProceed] = useState(false);

  useEffect(() => {
    async function generate() {
      if (submit) {
        let generated = await generateReport(submit.rapportID!!);
        if (generated) {
          submit.setDownloadLink(
            `${getBasePath()}/api/v1/rapporter/${submit.rapportID}/download`
          );
        } else {
          updateStep(FlowStateScreen.FailedToGenerate);
        }
      }
    }

    if (proceed && submit) {
      updateStep(step + 1);
      generate();
    }
  }, [step, submit, proceed, updateStep]);

  return (
    <div>
      <button
        type="button"
        css={css`
          box-sizing: border-box;
          background-color: ${valid ? colors.sekundaer900 : colors.wait};
          width: 200px;
          height: 40px;
          border-radius: 6px;
          border: 0;
          color: white;
          font-weight: bold;
          font-style: normal;
          font-family: Mulish;
          font-size: 2rem;
          margin: 8px;
          :hover {
            cursor: ${valid ? "pointer" : "default"};
            opacity: ${valid ? 0.7 : 1};
          }
        `}
        disabled={!valid}
        onClick={async () => {
          // navigation is controlled by the OrderConcent component on step 2
          if (valid && step !== 2) {
            updateStep(step + 1);
            if (sendForm) {
              let t = await sendForm.sendForm(sendForm.info);
              if (t) sendForm.updateRapportID(t);
            }
          }

          if (submit) {
            setShow(true);
          }
        }}
      >
        {step === 0 ? "Ok" : submit ? "Bestill rapport" : "Send inn"}
      </button>
      {show && <OrderConsent setProceed={setProceed} />}
    </div>
  );
};
