/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Form_Label_Style, Input_TextArea_Style } from "../styles/styles";

export const SimpleDropdown: FC<{
  title?: string;
  options: string[];
  reportStatus?: number;
  onChange?: (value: number) => void;
}> = ({ options, reportStatus, title, onChange }) => {
  return (
    <Container>
      <Title>{title || ""}</Title>
      <select
        className="dropDown"
        onChange={(event) =>
          onChange ? onChange(options.indexOf(event.target.value)) : () => {}
        }
        css={Input_TextArea_Style}
        defaultValue={options[reportStatus || 0]}
      >
        {options &&
          options.map((it) => (
            <option value={it} key={title + it} css={Select_tag_style}>
              {it}
            </option>
          ))}
      </select>
    </Container>
  );
};

const Select_tag_style = css`
  -webkit-box-shadow: 5px 5px 5px #666;
  -moz-box-shadow: 5px 5px 5px #666;
  box-shadow: 5px 5px 5px #666;
  display: inline-block;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 100%;
  @media (min-width: 1000px) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  ${Form_Label_Style}
`;
