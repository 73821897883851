type Props = {
  className?: string;
};

const MicrosoftIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.5,3.2410278V9.5H18V2L9.5,3.2410278z M2,9.5h6.5V3.3870239L2,4.3359985V9.5z M9.5,16.7589722L18,18v-7.5
          H9.5V16.7589722z M2,15.6640015l6.5,0.9489746V10.5H2V15.6640015z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MicrosoftIcon;
