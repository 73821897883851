import {
  boligTekstFormWithOutFiles,
  ReportType,
  staticContentResponse,
  uploadResponse,
  userResponseType,
} from "./types/types";
import { getBasePath } from "./utils/env";
import { Person } from "./utils/interfaces";
import axios from "axios";
import { isNumeric } from "./utils/form_validator";
const htmlpath = getBasePath();

export const sendFile = async (apiPath: string, file: File) => {
  const myHeaders = new Headers();
  myHeaders.append("File-Name", file.name);
  myHeaders.append("Content-Type", "application/pdf");

  if (!file) {
    return;
  }

  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
    body: File;
  } = {
    method: "POST",
    headers: myHeaders,
    credentials: "include",
    body: file,
  };

  try {
    const fileoneres = await fetch(apiPath, requestOptions);
    if (fileoneres.ok) {
      const response: uploadResponse = await fileoneres.json();
    return response;
    } else {
      return {id:"-1"}
    }
  } catch (error) {}
};

export const sendForm = async (formData: boligTekstFormWithOutFiles) => {

  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
    body: string;
  } = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(formData),
  };

  try {
    const request_respond: uploadResponse = await (
      await fetch(htmlpath + "/api/v1/rapporter", requestOptions)
    ).json();
    return request_respond.id;
  } catch (error) {
  }
};

export const getStaticContent = async (): Promise<staticContentResponse> => {
  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
  } = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };
  const staticContentResponse: staticContentResponse = (await (
    await fetch(htmlpath + "/api/v1/staticcontent/skjema", requestOptions)
  ).json()) as staticContentResponse;
  return staticContentResponse;
};

export const sendPictures = (
  files: File[],
  rapportID: string | undefined,
  setNumberOfPicturesUploaded: (n: number) => void,
  setError: (n?: number) => void
) => {
  if (files.length === 0) {
    return;
  } else if (!rapportID) {
    return;
  }

  const pictures_IDs: Response[] = [];

  Array.from(files).forEach(async (it) => {
    const filename = it.text.name; // grabs filename using Blob instead of File https://developer.mozilla.org/en-US/docs/Web/API/Blob/text

    let requestOptions: {
      method: string;
      headers: HeadersInit;
      credentials: RequestCredentials;
      body: File;
    } = {
      method: "POST",
      headers: {
        "Content-Type": it.type,
        "File-Name": filename,
      },
      credentials: "include",
      body: it,
    };

    try {
      const response = await fetch(
        htmlpath + `/api/v1/rapporter/${rapportID}/bilder`,
        requestOptions
      );
      if (response.status >= 200 && response.status < 300) {
        pictures_IDs.push(response);
        setNumberOfPicturesUploaded(pictures_IDs.length);

        setError(undefined);
      } else {
        setError(response.status);
      }
    } catch (error) {    }
  });
};

export const generateReport = async (rapportID: string): Promise<boolean> => {
  let requestOptions: { method: string; credentials: RequestCredentials } = {
    method: "POST",
    credentials: "include",
  };

  try {
    let res = await fetch(
      `${htmlpath}/api/v1/rapporter/${rapportID}/generate`,
      requestOptions
    );
    if (res.status === 201){
      return true
    }
  } catch (error) {}
  return false
};

export const getCurrentUser = async (): Promise<Person> => {
  let requestOptions: { method: string; credentials: RequestCredentials } = {
    method: "GET",
    credentials: "include",
  };
  const currentUser: userResponseType = (await (
    await fetch(htmlpath + "/session/currentuser", requestOptions)
  ).json()) as userResponseType;

  var person:Person = {
    id: currentUser.user.id,
    email: currentUser.user.email,
    name:currentUser.user.name,
    phone:currentUser.user.phone,
    office:currentUser.user.office,
    fakturaEmail: currentUser.user.fakturaEmail, 
    kontorAdresse: currentUser.user.kontorAdresse,
    isAdmin: currentUser.user.isAdmin,
  }
  return person
};

export const logoutUser = async (): Promise<boolean> => {
  let requestOptions: { method: string; credentials: RequestCredentials } = {
    method: "GET",
    credentials: "include",
  };
  const response = await fetch(htmlpath + "/session/logout", requestOptions);

  return response.status === 200;
};

export const getAllReports = async (): Promise<ReportType[]> => {
  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
  } = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  const allReports: ReportType[] = (await (
    await fetch(htmlpath + "/api/v1/rapporter", requestOptions)
  ).json()) as ReportType[];
  
  var convertedReports: ReportType[] = []
  allReports.forEach(report  => {
    var tempReport: ReportType = {
      id: report.id.toString(),
      name: report.name,
      oppdragsnummer: report.oppdragsnummer,
      adresse: report.adresse,
      poststed: report.poststed,
      userId: report.userId.toString(),
      dato: report.dato,
      status: report.status,
      lokaltStedsnavn: report.lokaltStedsnavn,
      prospekt: report.prospekt,
      boligtype: report.boligtype
    }
    convertedReports.push(tempReport)
  })

  return convertedReports;
};

export const getAllReportsForUser = async (userId:string): Promise<ReportType[]> => {
  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
  } = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  const allReports: ReportType[] = (await (
    await fetch(htmlpath + "/api/v1/rapporter/user/" + userId, requestOptions)
  ).json()) as ReportType[];

  return allReports;
};

export const downloadReport = async (): Promise<ReportType[]> => {
  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
  } = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  const allReports: ReportType[] = (await (
    await fetch(htmlpath + "/api/v1/rapporter", requestOptions)
  ).json()) as ReportType[];

  return allReports;
};

export const updateUserPersonaliaWithDB = async (user: Person) => {
  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
    body: string;
  } = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };
  await fetch(
    htmlpath + "/api/v1/user/" + user.id,
    requestOptions
  );
};

export const updateReport = async (report: ReportType) => {

  let requestOptions: {
    method: string;
    headers: HeadersInit;
    credentials: RequestCredentials;
    body: string;
  } = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(report),
  };
  await fetch(
    htmlpath + "/api/v1/rapporter/" + report.id,
    requestOptions
  );
};

export const getUserById = async(id:string) =>{

  let requestOptions: { method: string; credentials: RequestCredentials, } = {
    method: "GET",
    credentials: "include",
  };

  const user = (await (
    await fetch(htmlpath + "/api/v1/user/"+ id.toString(), requestOptions)
  ).json());

  var person:Person ={
    id: user.id,
    email: user.email,
    name:user.name,
    phone:user.phone,
    office:user.office,
    fakturaEmail: user.fakturaEmail,
    isAdmin: user.isAdmin,
  }
  return person;
}

export const deleteReport = async (rapportID: string): Promise<boolean> => {
  let requestOptions: { method: string; credentials: RequestCredentials } = {
    method: "POST",
    credentials: "include",
  };

  try {
    let res = await fetch(
      `${htmlpath}/api/v1/rapporter/${rapportID}/delete`,
      requestOptions
    );
    if (res.status === 202){
      return true
    }
  } catch (error) {}
  return false
};

// === Fetches business info from brreg
export const getOrgInfo = async (str: string) => {
  if (str.trim() === "") {
    return [];
  }
  if (isNumeric(str)) {
    // search using org.nr
    try {
      // prøver først å finne underenheter
      let data = await axios.get(
        "https://data.brreg.no/enhetsregisteret/api/underenheter?organisasjonsnummer=" +
          str,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (data.data._embedded) {
        return data.data._embedded.underenheter;
      }

      // hvis ingen underenheter ble funnet, prøv enheter
      data = await axios.get(
        "https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=" +
          str +
          "&konkurs=false",
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return data.data._embedded.enheter;
    } catch (error) {
      return [];
    }
  } else {
    try {
      // prøver først å finne underenheter
      let data = await axios.get(
        "https://data.brreg.no/enhetsregisteret/api/underenheter?navn=" +
          str +
          "&naeringskode=68.310,69.100",
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (data.data._embedded.underenheter.length >= 1) {
        return data.data._embedded.underenheter;
      }

      // hvis ingen underenheter ble funnet, prøv enheter
      data = await axios.get(
        "https://data.brreg.no/enhetsregisteret/api/enheter?navn=" +
          str +
          "&konkurs=false&naeringskode=68.310,69.100",
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return data.data._embedded.enheter;
    } catch (error) {
      return [];
    }
  }
};
