/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { kvittering } from "../types/types";
import { colors } from "../utils/colors";

export const Kvittering: FC<kvittering> = (props: kvittering) => {
  const personalia = [
    ["NAME", props.infoNotFiles.name],
    ["TELEFON", props.infoNotFiles.telefon],
    ["E-POST", props.infoNotFiles.epost],
    ["ORG. NR TIL MEGLERKONTOR", props.infoNotFiles.orgnrMeglerselskap],
    ["OPPDRAGSNUMMER", props.infoNotFiles.oppdragsnummer],
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        @media (max-width: 1300px) {
          flex-direction: column;
        }
      `}
    >
      <KvitteringSection title={"Personalia"} labels_values={personalia} />

      <VedleggSection
        filenames={props.pictures}
        egenerklaering={props.egenerklaeringName}
        tilstandsrapport={props.tilstandsrapportName}
      />
    </div>
  );
};

const VedleggSection: FC<{
  filenames: string[];
  egenerklaering: string;
  tilstandsrapport: string;
}> = ({ filenames, egenerklaering, tilstandsrapport }) => {
  return (
    <div
      css={css`
        min-width: 180px;
        margin: 0px 30px;
        @media (max-width: 1000px) {
          margin: 20px 30px;
        }
      `}
    >
      <h2>Vedlegg</h2>
      <div
        css={css`
          padding-left: 15px;
          padding-right: 55px;
          padding-bottom: 15px;
          font-size: 10px;
          background-color: #f4f4f4;
          max-height: 180px;
          overflow-y: auto;
          color: ${colors.sekundaer900};
          border: 1px solid #49496c;
          box-sizing: border-box;
          border-radius: 12px;
          align-items: unset;
        `}
      >
        <p
          css={css`
            border-bottom: 1px solid #aeaeae;
            text-align: left;
            font-size: 16px;
          `}
        >
          {egenerklaering}
        </p>
        <p
          css={css`
            border-bottom: 1px solid #aeaeae;
            text-align: left;
            font-size: 16px;
          `}
        >
          {tilstandsrapport}
        </p>
        {filenames.map((it) => (
          <p
            key={it}
            css={css`
              border-bottom: 1px solid #aeaeae;
              text-align: left;
              font-size: 16px;
            `}
          >
            {it}
          </p>
        ))}
      </div>
    </div>
  );
};

const KvitteringSection: FC<{
  title: string;
  labels_values: string[][];
}> = ({ title, labels_values }) => {
  return (
    <div
      css={css`
        margin: 0px 30px;
        @media (max-width: 1000px) {
          margin: 20px 30px;
        }
      `}
    >
      <h2>{title}</h2>
      {labels_values.map((element, index) => (
        <KvitteringSectionListing
          key={index + element[1]}
          boligStatus={element[1]}
          caps={element[0]}
        />
      ))}
    </div>
  );
};

const KvitteringSectionListing: FC<{
  boligStatus: string | number;
  caps: string;
}> = ({ boligStatus, caps }) => {
  return (
    <div
      css={css`
        font-size: 16px;
        color: ${colors.sekundaer900};
        border-bottom: 1px solid #49496c;
        display: flex;
        align-items: center;
        height: 35px;
        min-width: 425px;
      `}
    >
      &nbsp;<b>{caps}:</b>&nbsp;{boligStatus}
    </div>
  );
};
