export const validateText = (val: string, isOppdrag?: boolean) => {
  if (val.trim() === "") {
    return isOppdrag ? "Skriv inn oppdragsnummer" : "Skriv inn stedsnavn";
  }

  if (!/^[a-øA-Æ0-9{\s}]*$/.test(val)) {
    return "Ugyldige karakterer";
  }

  return null;
};

export const validateNumbers = (val: string, requiredLength?: number) => {
  if (/^\d+$/.test(val.replace(/\s/g, "")) !== true) {
    return "Skriv inn bare siffer";
  }

  if (requiredLength && val.replace(/\s/g, "").length !== requiredLength) {
    return `Dette feltet krever ${requiredLength} siffer`;
  }

  return null;
};

export const validateEmail = (val: string) => {
  const regex = /([a-æ]|[A-Æ]|[0-9])+(@)(.)+./;
  if (regex.test(val)) {
    return null;
  }
  if (val.trim() === "") {
    return "Epost er nødvendig";
  }
  return "Skriv inn en gyldig e-postadresse";
};

export const validateString = (val: string) => {
  const regex = /([a-æ]|[A-Æ])+/;
  if (regex.test(val)) {
    return null;
  }
  return "Skriv inn bare bokstavkarakterer";
};

export const isValidNumber = (val: string, requiredLength?: number) => {
  if (/^\d+$/.test(val.replace(/\s/g, "")) !== true) {
    return false;
  }

  if (requiredLength && val.replace(/\s/g, "").length !== requiredLength) {
    return false;
  }

  return true;
};

export const isValidText = (val: string) => {
  if (val.trim() === "") {
    return false;
  }

  return true;
};

export const isValidEmail = (val: string) => {
  const regex = /([a-æ]|[A-Æ]|[0-9])+(@)(.)+./;
  if (regex.test(val)) {
    return true;
  }
  if (val.trim() === "") {
    return false;
  }
  return false;
};

export const isNumeric = (str: string) => {
  return !isNaN(Number(str));
};
