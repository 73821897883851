import styled from "@emotion/styled";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { deleteReport, getAllReports, getUserById } from "../Api";
import AdminListItem from "../components/AdminListItem";
import { ReportStatus, ReportType } from "../types/types";
import { colors } from "../utils/colors";
import { Person } from "../utils/interfaces";
import UserContext, { AccessControl } from "../utils/UserContext";

interface NumReportStatuses {
  all: number;
  new: number;
  billed: number;
  paymentReceived: number;
}

const AdminPanel: FC<RouteComponentProps> = () => {
  const [allReports, setAllReports] = useState<ReportType[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [users, setUsers] = useState<Person[]>([]);
  const [showData, setShowData] = useState<ReportType[]>();
  const [filterBy, setFilterBy] = useState<ReportStatus | undefined>();
  const [numReports, setNumReports] = useState<NumReportStatuses>({
    all: 0,
    new: 0,
    billed: 0,
    paymentReceived: 0,
  });

  const { userSession } = useContext(UserContext);
  AccessControl(userSession, true);

  const refreshNumReports = useCallback(() => {
    setNumReports(() => ({
      all: allReports.length,
      new: allReports.filter((report) => report.status === 0).length,
      billed: allReports.filter((report) => report.status === 1).length,
      paymentReceived: allReports.filter((report) => report.status === 2)
        .length,
    }));
  }, [allReports]);

  const applyFilter = useCallback(() => {
    if (filterBy === undefined) {
      setShowData(
        allReports.sort(
          (a, b) => new Date(b.dato).getTime() - new Date(a.dato).getTime()
        )
      );
    } else {
      var templist = allReports.filter((report) => report.status === filterBy);
      setShowData(
        templist.sort(
          (a, b) => new Date(b.dato).getTime() - new Date(a.dato).getTime()
        )
      );
    }
    refreshNumReports();
  }, [allReports, filterBy, refreshNumReports]);

  const getUserWithId = (id: string): Person => {
    return users.filter((user) => {
      return user.id === id;
    })[0];
  };

  const deleteReportAndRefresh = useCallback(
    (id: string) => {
      deleteReport(id);
      var filteredReports = allReports.filter(
        (item) => item.id.toString() !== id
      );
      setAllReports(filteredReports);
    },
    [allReports]
  );

  const getFilterText = (): string => {
    switch (filterBy) {
      case 0:
        return "Ikke-fakturerte rapporter";
      case 1:
        return "Mangler betaling";
      case 2:
        return "Fakturerte og betalte rapporter";
      default:
        return "Alle rapporter";
    }
  };

  const getNoResultText = (): string => {
    switch (filterBy) {
      case 0:
        return "Ingen ufakturerte rapporter, bra jobbet!";
      case 1:
        return "Ingen ubetalte fakturerte rapporter";
      case 2:
        return "Ingen mottatte betalinger";
      default:
        return "Ingen registrerte rapporter";
    }
  };

  useEffect(() => {
    async function fetchReports() {
      var tempReports = await getAllReports();
      var tempUserIds = Array.from(
        new Set(tempReports?.map((item) => item.userId))
      );

      if (tempUserIds.length !== userIds.length) {
        setUserIds(tempUserIds);
      }
      setAllReports(tempReports.filter((report) => report.prospekt !== null));
    }
    fetchReports();
  }, [userIds]);

  useEffect(() => {
    applyFilter();
  }, [allReports, filterBy, applyFilter]);

  useEffect(() => {
    async function fetchUserInfo() {
      userIds.forEach(async (id) => {
        var user = await getUserById(id);
        if (user) {
          setUsers((prev) => [...prev, user]);
        }
      });
    }
    fetchUserInfo();
  }, [userIds]);

  useEffect(() => {
    if (allReports.length !== numReports.all) {
      refreshNumReports();
    }
  }, [allReports, numReports, refreshNumReports]);

  return (
    <Wrapper>
      <Padding />
      <Sidebar>
        <FixedBox>
          <Title>Status</Title>
          <FilterButton onClick={() => setFilterBy(undefined)}>
            Alle raporter: {numReports.all}
          </FilterButton>
          <FilterButton onClick={() => setFilterBy(0)}>
            Ikke fakturert: {numReports.new}
          </FilterButton>
          <FilterButton onClick={() => setFilterBy(1)}>
            Faktura sendt: {numReports.billed}
          </FilterButton>
          <FilterButton onClick={() => setFilterBy(2)}>
            Faktura betalt: {numReports.paymentReceived}
          </FilterButton>
        </FixedBox>
      </Sidebar>
      <Container>
        <ContentTitle>{getFilterText()}</ContentTitle>
        {showData !== undefined && showData?.length > 0 ? (
          showData?.map((item) => {
            return AdminListItem(
              item,
              applyFilter,
              deleteReportAndRefresh,
              getUserWithId(item.userId)
            );
          })
        ) : (
          <NoResult>{getNoResultText()}</NoResult>
        )}
      </Container>
    </Wrapper>
  );
};
export default AdminPanel;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  text-align: center;
  margin-top: 9rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  min-height: 100vh;
  align-self: stretch;
  padding-top: 9rem;
  background-color: ${colors.sekundaer500};
  color: white;
  @media (max-width: 1000px) {
    width: 100%;
    min-height: auto;
    padding-top: 0;
    padding-bottom: 32px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  padding: 16px 0;
  @media (max-width: 1000px) {
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
`;

const FilterButton = styled.button`
  height: 48px;
  text-decoration: none;
  box-shadow: 0 2px 4px #0004;
  background-color: ${colors.sekundaer900};
  border: none;
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 1rem;
  color: white;
  padding: 8px 16px;
  border-radius: 40px;
  margin-bottom: 8px;
  width: 240px;
  text-align: left;
  padding-left: 24px;
  cursor: pointer;

  :focus  {
    background-color: ${colors.primaer900};
    color: white;
  }

  :active {
    opacity: 0.5;
  }
`;

const Padding = styled.div`
  height: 9rem;
`;

const Title = styled.h1`
  font-weight: 600;
  color: white;
`;

const ContentTitle = styled.h2`
  font-weight: 600;
  color: ${colors.sekundaer900};
  padding: 16px 0;
`;

const NoResult = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  box-sizing: border-box;
  border-radius: 6px;
  margin: 8px 8px 0px 8px;
  padding: 48px;

  color: ${colors.sekundaer900};

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 90%;
  }
`;

const FixedBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: fixed;
  @media (max-width: 1000px) {
    position: static;
  }
`;
