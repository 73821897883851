import styled from "@emotion/styled";
import { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Fakturering,
  JuridiskAnsvar,
  Opphavsrett,
  SalgsObjektPunkter
} from "../res/infoText";
import { PaddingTop } from "../styles/styles";
import { colors } from "../utils/colors";
import ScrollToTop from "../utils/ScrollToTop";

const Information: FC<RouteComponentProps> = () => {
  return (
    <Wrapper>
      <ScrollToTop />
      <PaddingTop padding={"100px"} />
      <Container>
        <Title>{"Informasjon"}</Title>
        <InfoContainer>
          <Padding>
            <BodyWrapper>
              <TextBox>
                <SubHeader>{"Fakturering"}</SubHeader>
                <BodyText>{Fakturering}</BodyText>
              </TextBox>
              <TextBox>
                <SubHeader>{"Opphavsrett"}</SubHeader>
                <BodyText>{Opphavsrett}</BodyText>
              </TextBox>
              <TextBox>
                <SubHeader>{"Juridisk ansvar"}</SubHeader>
                <BodyText>{JuridiskAnsvar}</BodyText>
              </TextBox>
              <TextBox>
                <SubHeader>{"Beskrivelser innen følgende områder:"}</SubHeader>
                {SalgsObjektPunkter.map((element) => {
                  return <Bullet>{element}</Bullet>;
                })}
              </TextBox>
            </BodyWrapper>
          </Padding>
        </InfoContainer>
      </Container>
    </Wrapper>
  );
};

export default Information;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-align: center;
  color: ${colors.sekundaer900};
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 4rem;
  margin: 7rem 0;
  font-weight: bolder;
  background-color: ${colors.bannerBackground};
`;

const InfoContainer = styled.div`
  width: 60%;
  border-radius: 24px;
  background-color: white;
  border: 1px solid ${colors.sekundaer500};
  box-shadow: 8px 8px 0px ${colors.sekundaer500};
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
  text-align: left;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const Padding = styled.div`
  padding: 32px;
  justify-content: center;
  align-items: center;
`;

const SubHeader = styled.h2`
  font-size: 3rem;
`;

const BodyText = styled.p`
  font-size: 2rem;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const TextBox = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Bullet = styled.li`
  margin-left: 40px;
  font-size: 1.8rem;
`;
