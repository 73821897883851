export const Salgsbeskrivelser = "Salgsbeskrivelser for bolig";
export const frontPageHeaderTitle = "Boligbeskrivelser levert på sekunder!";
export const frontPageSubHeaderTitle = "Beskrivelser innen følgende områder:";

export const ViHarGjortDetEnkelt =
  "Vi har gjort det enkelt for deg som eiendomsmegler: 1 pris, 1 komplett rapport med tekst for dine boligsalg, og det beste er at alt leveres på sekunder!";

export const ViGjorDetEnkelt =
  "Vi gjør det enkelt! Du kjøper 1 pakke som inkluderer:";
export const KomplettRapport =
  "Komplett rapport med tekster til salgsoppgaven, annonsen og viktig fakta om salgsobjektet.";

export const Innhold = "Innhold:";

export const EmojiSalesPunkt = "💰 Prøv gratis - Ingen forpliktelser!";

export const EmojiTekstPunkter = [
  "✅ Vi leverer fakta-beskrivelser for deg i 🚀-fart.",
  "✅ Beskrivelser levert innen 10 sekunder fra bestilling av rapport.",
  "✅ Du må kun laste opp et bilde av hvert rom, tilstandsrapport og egenerklæringsskjema så fikser vi resten.",
  "✍️ Du som megler kjenner boligen best og legger inn ditt personlige preg",
];

export const Fakturering =
  "Det faktureres per bestilling. Fakturainformasjon: Meglers fulle navn, oppdragsnummer, boligens adresse for enkel kontering i deres økonomisystem.";

export const Opphavsrett =
  "Annonsetekster og innhold laget av TXTFLOW er under beskyttelse av åndsverkloven, og tekster utviklet av TXTFLOW skal kun benyttes til det oppdraget det ble bestilt for. Brudd på reglene vil føre til at megler mottar faktura for oppdraget, og får en skriftlig advarsel om utestengelse fra tjenesten.";

export const JuridiskAnsvar =
  "Gitt korrekte opplysninger og dokumentasjon skal TXTFLOW levere tekster av kvalitet og riktighet. Forøvrig viktig å være bevisst på at tjenesten er i en Beta fase. Ved feil anbefaler vi å gi tilbakemelding til hei@txtflow.no.  Vi har ikke et juridisk ansvar for innholdet i teksten. Publisert tekst er meglers/selgers ansvar.";

export const SalgsObjektPunkter = [
  "Oppsummering av tilstandsgrader (TG)",
  "Oppsummering av egenerklæring",
  "Overskrifter",
  "Annonsetekst",
  "Høydepunkter",
  "Beliggenhet",
  "Servicetilbud",
  "Oppvarming",
  "Parkering",
  "Planløsning",
  "Rombeskrivelser",
];
