import { FC, useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { getCurrentUser } from "./Api";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import AdminPanel from "./pages/AdminPanel";
import Home from "./pages/Home";
import Information from "./pages/Information";
import Login from "./pages/Login";
import MyProfile from "./pages/MyProfile";
import Onboarding from "./pages/Onboarding";
import OrderForm from "./pages/OrderForm";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import UserContext, { UserSession } from "./utils/UserContext";

export const retrieveUserProfile = async (
  setUserSessionCallback: (args: UserSession) => void
) => {
  (async () => {
    await getCurrentUser().then((res) => {
      setUserSessionCallback({
        user: res,
        loaded: true,
      });
    });
  })().catch(() => {
    setUserSessionCallback({
      user: undefined,
      loaded: true,
    });
  });
};

const App: FC = () => {
  const [userSession, setUserSession] = useState<UserSession>({
    loaded: false,
    user: undefined,
  });

  useEffect(() => {
    if (!userSession.loaded) {
      retrieveUserProfile(setUserSession).then(() => {
        setUserSession(userSession);
      });
    }
  }, [userSession, setUserSession]);

  return (
    <UserContext.Provider value={{ userSession, setUserSession }}>
      <Router>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/bestilling" component={OrderForm} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/min_profil" component={MyProfile} />
        <Route exact path="/onboarding" component={Onboarding} />
        <Route exact path="/info" component={Information} />
        <Route exact path="/admin" component={AdminPanel} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Footer position={"static"} />
      </Router>
    </UserContext.Provider>
  );
};

export default App;
