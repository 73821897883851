/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { SelectedFilesListType } from "../types/types";
import { colors } from "../utils/colors";
import Progressbar from "./Progressbar";

const hide_scrollbar = `
  ::-webkit-scrollbar {
    display: none
  }
  
  -ms-overflow-style: none; 
  scrollbar-width: none;  
`;

export const SelectedFileList: FC<SelectedFilesListType> = ({
  photos,
  file,
  title,
  numberOfPictures = 0,
  numberOfPicturesUploaded,
  fileUploaded,
}) => {
  const calculateProgress = () => {
    const numOfPicsUploaded = numberOfPicturesUploaded
      ? numberOfPicturesUploaded
      : 0;
    const numOfPics = numberOfPictures ? numberOfPictures : 1;
    return numOfPicsUploaded / numOfPics;
  };

  return (
    <div
      css={css`
        height: 100%;
        max-height: 200px;
        width: 100%;
        max-width: 255px;
        background-color: ${!file && !(photos && photos!!.length > 0)
          ? colors.ikkeGodkjent
          : colors.godkjent};
        border-radius: 12px;
        border: 1px solid black;
        overflow-y: scroll;
        ${hide_scrollbar}
      `}
    >
      <h2>{title}:</h2>
      <ProgressbarBox>
        {numberOfPictures ? (
          <Progressbar
            bgcolor={colors.sekundaer900}
            completed={Math.max(0.15, calculateProgress()) * 100}
          />
        ) : file ? (
          <Progressbar
            bgcolor={colors.sekundaer900}
            completed={(fileUploaded ? 1 : 0.45) * 100}
          />
        ) : undefined}
      </ProgressbarBox>
      <FileList photos={numberOfPictures !== 0}>
        {photos ? (
          photos.map((it) => (
            <ImageBox key={it.name}>
              <img
                src={`${process.env.PUBLIC_URL}/images/picture_icon.svg`}
                alt=""
              />
              <label
                css={css`
                  font-size: 10px;
                  color: ${colors.sekundaer900};
                `}
              >
                &nbsp;{it.name.length > 20 ? it.name.substring(0, 19) : it.name}
              </label>
            </ImageBox>
          ))
        ) : (
          <div
            css={css`
              width: 80%;
              padding: 0 10%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                ${file && `border-bottom: 1px solid #AEAEAE;`}
              `}
            >
              {file ? (
                <img
                  src={`${process.env.PUBLIC_URL}/images/file_icon.svg`}
                  alt=""
                />
              ) : undefined}
              <label
                css={css`
                  font-size: 10px;
                  color: ${colors.sekundaer900};
                `}
              >
                &nbsp;&nbsp;{file?.name}
              </label>
            </div>
          </div>
        )}
      </FileList>
    </div>
  );
};

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #aeaeae;
  margin: 0 10%;
  width: 80%;
  min-height: 20px;
`;

const FileList = styled.div<{ photos: boolean }>`
  max-height: ${(props) => (props.photos ? "40%" : "auto")};
  overflow: scroll;
  padding: 0 15px;
  ${hide_scrollbar}
`;

const ProgressbarBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;
