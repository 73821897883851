import styled from "styled-components";
import { updateReport } from "../Api";
import { ReportType } from "../types/types";
import { colors } from "../utils/colors";
import { getBasePath } from "../utils/env";
import { Person } from "../utils/interfaces";
import { SimpleDropdown } from "./SimpleDropdown";

const AdminListItem = (
  report: ReportType,
  statusUpdatedCallback: () => void,
  deleteReportCallback: (id: string) => void,
  user?: Person
): JSX.Element => {
  const updateStatus = (value: number) => {
    updateReport({ ...report, status: value });
    report.status = value;
    statusUpdatedCallback();
  };

  return (
    <Box key={`box_${report.id}`}>
      <SimpleDropdown
        title={"Status"}
        options={["Ikke fakturert", "Faktura sendt", "Faktura betalt"]}
        reportStatus={report.status}
        onChange={updateStatus}
      />
      <InfoBox key={`infobox_${report.id}`}>
        <HeaderText key={`headertext_${report.id}`}>
          Oppdragsnummer: {report.oppdragsnummer}
        </HeaderText>
        <InfoText key={`infotext_${report.id}`}>
          {report.adresse}, {report.poststed}
        </InfoText>
        <InfoText>Dato: {new Date(report.dato).toLocaleString()}</InfoText>
        <InfoText>Rapport-id: {report.id}</InfoText>
      </InfoBox>
      <InfoBox>
        <HeaderText>{user?.office?.name || ""}</HeaderText>
        <InfoText>Org.nr: {user?.office?.orgNummer || ""}</InfoText>
        <InfoText>Megler: {user?.name || ""}</InfoText>
        <InfoText>Faktura-epost: {user?.fakturaEmail || ""}</InfoText>
      </InfoBox>
      <ButtonContainer>
        <DownloadButton
          key={`downloadbutton_${report.id}`}
          href={`${getBasePath()}/api/v1/rapporter/${report.id}/download`}
        >
          Last ned rapport
        </DownloadButton>
        <DeleteButton
          key={`deletebutton_${report.id}`}
          onClick={() => deleteReportCallback(report.id.toString())}
        >
          Slett rapport
        </DeleteButton>
      </ButtonContainer>
    </Box>
  );
};

export default AdminListItem;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid ${colors.sekundaer900};
  box-sizing: border-box;
  border-radius: 6px;
  margin: 16px 8px 0px 8px;
  padding: 8px;
  color: ${colors.sekundaer900};
  box-shadow: 0 2px 4px #0004;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 90%;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 35%;
  margin-left: 1rem;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 1.7rem;
`;
const InfoText = styled.p`
  font-size: 1.7rem;
`;

const ButtonStyle = `
  text-decoration: none;
  box-shadow: 0 2px 4px #0004;
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 1rem;
  padding: 8px 16px;
  border-radius: 64px;
  cursor: pointer;
  border:none;
  font-family: Mulish;
  
`;

const DownloadButton = styled.a`
  ${ButtonStyle}
  background-color: ${colors.sekundaer900};
  color: white;
`;

const DeleteButton = styled.button`
  ${ButtonStyle}
  background-color: ${colors.delete};
  color: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin: 8px 0;
  }
  @media (max-width: 1000px) {
    padding-bottom: 16px;
    flex-direction: row;
    > * + * {
      margin: 0;
      margin-left: 16px;
    }
  }
`;
