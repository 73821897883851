import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UserInfoType } from "../types/enums";
import {
  isNumeric,
  isValidEmail,
  isValidNumber,
  isValidText,
} from "../utils/form_validator";
import { changeStateString } from "../pages/OrderForm";
import UserContext from "../utils/UserContext";
import PersonInfoContainer from "./PersonInfoContainer";
import { Business } from "../utils/interfaces";
import { getOrgInfo, updateUserPersonaliaWithDB } from "../Api";
import BusinessDisplayContainer from "./BusinessDisplayContainer";
import { retrieveUserProfile } from "../App";

const MyProfileInfo = (): JSX.Element => {
  const { userSession, setUserSession } = useContext(UserContext);

  // org
  const [meglerKontor, setMeglerKontor] = useState<string>(
    userSession.user?.office?.name || ""
  );

  const [validOrgNr, setValidOrgNr] = useState<boolean>(
    isNumeric(meglerKontor)
      ? isValidNumber(meglerKontor, 9)
      : isValidText(meglerKontor)
  );

  const [multipleOrgResults, setMultipleOrgResults] = useState<Array<Business>>(
    []
  );

  // Telefonnr
  const [userPhone, setphoneNumber] = useState<string>(
    userSession.user?.phone || ""
  );

  const [validPhone, setValidPhone] = useState<boolean>(
    isValidNumber(userPhone, 8)
  );

  // Fakturaepost
  const [fakturaEmail, setFakturaEmail] = useState<string>(
    userSession.user?.fakturaEmail || ""
  );

  const [validFakturaEmail, setValidFakturaEmail] = useState<boolean>(
    isValidEmail(fakturaEmail)
  );

  // Kontoradresse
  const [adresseKontor, setAdresseKontor] = useState<string>(
    userSession.user?.kontorAdresse || ""
  );

  const [validAdresseKontor, setValidAdresseKontor] = useState<boolean>(
    isValidText(adresseKontor)
  );

  const [userHasEdited, setUserEdited] = useState<boolean>(false);
  useEffect(() => {
    const initForm = () => {
      if (meglerKontor === "") {
        setMeglerKontor(userSession.user?.office?.name || "");
      }
      if (userPhone === "") {
        setphoneNumber(userSession.user?.phone || "");
      }

      if (fakturaEmail === "") {
        setFakturaEmail(userSession.user?.fakturaEmail || "");
      }

      if (adresseKontor === "") {
        setAdresseKontor(userSession.user?.kontorAdresse || "");
      }
    };

    !userSession.loaded &&
      retrieveUserProfile(setUserSession).then(() => {
        setUserSession(userSession);
      });
    // initialize the form only before the user has edited
    if (!userHasEdited && userSession.loaded) {
      initForm();
      validateInfo(meglerKontor, 255, UserInfoType.ORG);
      validateInfo(userPhone, 8, UserInfoType.PHONE);
      validateInfo(fakturaEmail, 255, UserInfoType.FAKTURAEMAIL);
      validateInfo(adresseKontor, 255, UserInfoType.KONTORADRESSE);
    }
  }, [
    userSession,
    setUserSession,
    fakturaEmail,
    meglerKontor,
    userPhone,
    userHasEdited,
    adresseKontor,
  ]);

  const validateInfo = (
    value: string,
    targetLength?: number,
    type?: UserInfoType
  ) => {
    switch (type) {
      case UserInfoType.PHONE:
        setValidPhone(isValidNumber(value, targetLength));
        break;
      case UserInfoType.ORG:
        if (isNumeric(value)) {
          setValidOrgNr(isValidNumber(value, targetLength));
        } else {
          setValidOrgNr(isValidText(value));
        }
        break;
      case UserInfoType.FAKTURAEMAIL:
        setValidFakturaEmail(isValidEmail(value));
        break;
      case UserInfoType.KONTORADRESSE:
        setValidAdresseKontor(isValidText(value));
        break;
      default:
        break;
    }
  };

  const setBusiness = (business: Business) => {
    if (userSession.user) {
      let updatedUser = {
        ...userSession.user,
        office: { orgNummer: business.orgNummer, name: business.name },
      };
      setUserSession({
        ...userSession,
        user: updatedUser,
      });
      setMeglerKontor(business.name || "");
      setMultipleOrgResults([]);
      updateUserPersonaliaWithDB(updatedUser);
    }
  };

  return (
    <ListWrapper>
      <PersonInfoContainer
        title={"Navn:"}
        value={userSession.user?.name || ""}
        editable={false}
      />
      <PersonInfoContainer
        title={"Epost:"}
        value={userSession.user?.email || ""}
        editable={false}
      />
      <PersonInfoContainer
        title={"Bedrift:"}
        value={meglerKontor}
        valid={validOrgNr}
        placeholder={"Skriv inn org.nr. eller bedriftsnavn"}
        onChangeInputElement={(event) => {
          const targetLength: number = isNumeric(meglerKontor) ? 9 : 255;
          if (event.target.value.replace(/\s/g, "").length <= targetLength) {
            changeStateString(event, setMeglerKontor);
            validateInfo(event.target.value, targetLength, UserInfoType.ORG);
            if (!isNumeric(event.target.value)) {
              getOrgInfo(event.target.value).then((res) => {
                let tempList: Array<Business> = [];
                if (res) {
                  res.forEach(
                    (element: { navn: any; organisasjonsnummer: any }) => {
                      tempList.push({
                        name: element.navn,
                        orgNummer: element.organisasjonsnummer,
                      });
                    }
                  );
                  setMultipleOrgResults(tempList);
                }
              });
            } else {
              setMultipleOrgResults([]);
              if (isValidNumber(event.target.value, 9)) {
                getOrgInfo(event.target.value).then((res) => {
                  if (res.length >= 1) {
                    let business: Business = {
                      name: res[0].navn,
                      orgNummer: res[0].organisasjonsnummer,
                    };
                    setBusiness(business);
                  }
                });
              }
            }
            setUserEdited(true);
          }
        }}
      />
      {multipleOrgResults.map((business) => {
        return (
          <BusinessDisplayContainer
            {...business}
            onClick={() => {
              setBusiness(business);
            }}
          />
        );
      })}
      <PersonInfoContainer
        title={"Kontoradresse:"}
        value={adresseKontor}
        valid={validAdresseKontor}
        placeholder={"Skriv inn kontoradressen"}
        onChangeInputElement={(event) => {
          if (event.target.value.replace(/\s/g, "").length <= 255) {
            changeStateString(event, setAdresseKontor);
            validateInfo(
              event.target.value,
              undefined,
              UserInfoType.KONTORADRESSE
            );
          }
          setUserEdited(true);
        }}
        onLock={(newValue: string) => {
          if (userSession.user) {
            let updatedUser = { ...userSession.user, kontorAdresse: newValue };
            setUserSession({
              ...userSession,
              user: updatedUser,
            });
            updateUserPersonaliaWithDB(updatedUser);
          }
        }}
      />
      <PersonInfoContainer
        title={"Telefon nr:"}
        value={userPhone}
        valid={validPhone}
        placeholder={"Skriv inn telefon nr"}
        onChangeInputElement={(event) => {
          const targetLength = 8;
          if (event.target.value.replace(/\s/g, "").length <= targetLength) {
            changeStateString(event, setphoneNumber);
            validateInfo(event.target.value, targetLength, UserInfoType.PHONE);
          }
          setUserEdited(true);
        }}
        onLock={(newValue: string) => {
          if (userSession.user) {
            let updatedUser = { ...userSession.user, phone: newValue };
            setUserSession({
              ...userSession,
              user: updatedUser,
            });

            updateUserPersonaliaWithDB(updatedUser);
          }
        }}
      />
      <PersonInfoContainer
        title={"Faktura-epost:"}
        value={fakturaEmail}
        valid={validFakturaEmail}
        placeholder={"Skriv inn faktura-epost"}
        onChangeInputElement={(event) => {
          if (event.target.value.replace(/\s/g, "").length <= 255) {
            changeStateString(event, setFakturaEmail);
            validateInfo(
              event.target.value,
              undefined,
              UserInfoType.FAKTURAEMAIL
            );
          }
          setUserEdited(true);
        }}
        onLock={(newValue: string) => {
          if (userSession.user) {
            let updatedUser = { ...userSession.user, fakturaEmail: newValue };
            setUserSession({
              ...userSession,
              user: updatedUser,
            });
            updateUserPersonaliaWithDB(updatedUser);
          }
        }}
      />
    </ListWrapper>
  );
};

export default MyProfileInfo;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 90%;
    margin-left: 0;
  }
`;
