export const colors = {
  bannerBackground: "#F9F9F9",
  leftBackground: "#F4F4F4",
  primaer300: "#E9F5FD",
  primaer900: "#ADDAF7", //Primær/900
  sekundaer500: "#666CA0",
  sekundaer900: "#000A61", //Sekundær/900
  godkjent: "#E7F5F2",
  ikkeGodkjent: "#F8E8E5",
  transparent: "transparent",
  locked: "#EBEBEB",
  delete: "#cf3535",
  wait: "#A6A6B4",
};
