import { ChangeEvent, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ListItem from "../components/ListItem";
import { getAllReportsForUser } from "../Api";
import { ReportType } from "../types/types";
import { colors } from "../utils/colors";
import UserContext from "../utils/UserContext";

const MyProspects = () => {
  const [allReports, setAllReports] = useState<ReportType[]>();
  const [searchString, setSearchString] = useState<string>("");
  const [showData, setShowData] = useState<ReportType[]>();

  const { userSession } = useContext(UserContext);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);

    let filteredData;
    // oppdragsnumre skal bare ha siffer
    if (isNaN(Number(event.target.value))) {
      filteredData = allReports?.filter((element) => {
        return `${element.adresse}, ${element.poststed}`
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
    } else {
      filteredData = allReports?.filter((element) => {
        return element.oppdragsnummer
          .toString()
          .toLowerCase()
          .includes(event.target.value);
      });
    }
    setShowData(filteredData);
  };

  useEffect(() => {
    async function func() {
      if (userSession.user !== undefined) {
        setAllReports(await getAllReportsForUser(userSession.user.id));
      }
    }
    func();
  }, [userSession.user]);

  useEffect(() => {
    var templist = allReports?.filter((report) => report.prospekt !== null);
    setShowData(
      templist?.sort(
        (a, b) => new Date(b.dato).getTime() - new Date(a.dato).getTime()
      )
    );
  }, [allReports]);

  return (
    <ListWrapper>
      <SearchInput
        placeholder="Søk på oppdragsnummer eller adresse"
        value={searchString}
        onChange={handleInputChange}
      ></SearchInput>
      {showData &&
        showData.map((item) => {
          return ListItem(item);
        })}
    </ListWrapper>
  );
};

export default MyProspects;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SearchInput = styled.input`
  border: 1px solid ${colors.sekundaer900};
  box-sizing: border-box;
  box-shadow: 0px 0px 4px ${colors.primaer900};
  border-radius: 6px;
  display: flex;
  align-items: stretch;
  width: 40%;
  resize: none;
  height: 40px;
  margin-top: 10px;
  padding: 8px;
  margin-bottom: 8px;
  @media (max-width: 1000px) {
    width: 90%;
    max-width: 100%;
    margin-left: 0;
  }
`;
