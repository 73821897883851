import { useContext } from "react";
import styled from "styled-components";
import { HeaderPadding } from "../styles/styles";
import { colors } from "../utils/colors";
import UserContext from "../utils/UserContext";

const MyProfileBanner = (): JSX.Element => {
  const { userSession } = useContext(UserContext);
  return (
    <BannerBackground>
      <HeaderPadding />
      <h1>Min Profil</h1>
      <h2>{userSession.user?.name || "Oisann, noe gikk galt."}</h2>
    </BannerBackground>
  );
};

export default MyProfileBanner;

const BannerBackground = styled.div`
  background-color: ${colors.primaer300};
`;
