import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { colors } from "../utils/colors";

const handleExternalLinkClick = (path: string) => {
  window.open(path);
};

type WrapperProps = {
  position: string;
};

export const Footer: FC<WrapperProps> = (props: { position: string }) => {
  return (
    <Wrapper position={props.position}>
      <FooterColumn>
        <FooterRow>
          <Block>
            <CustomLink to="/terms-and-conditions">Brukervilkår</CustomLink>
            <CustomLink to="/privacy-policy">Personvernerklæring</CustomLink>
            <CustomLink
              to="#"
              onClick={(e) => {
                handleExternalLinkClick(
                  "https://www.instagram.com/txtflow.no/"
                );
                e.preventDefault();
              }}
            >
              Instagram
            </CustomLink>
            <CustomLink
              to="#"
              onClick={(e) => {
                handleExternalLinkClick(
                  "https://www.linkedin.com/company/txtflow/about/"
                );
                e.preventDefault();
              }}
            >
              LinkedIn
            </CustomLink>
            <CustomLink
              to="#"
              onClick={(e) => {
                handleExternalLinkClick("https://www.facebook.com/txtflow/");
                e.preventDefault();
              }}
            >
              Facebook
            </CustomLink>
            <Text>
              Kontakt: {"\u00A0"}
              <CustomLink
                to="#"
                onClick={(e) => {
                  window.location.href = "mailto:hei@txtflow.no";
                  e.preventDefault();
                }}
                target="_blank"
              >
                hei@txtflow.no
              </CustomLink>
            </Text>
          </Block>
        </FooterRow>
        <FooterRow>
          <Block>
            <HeaderText>
              TXTFLOW en tjeneste levert av Interpretify AS ©{" "}
              {new Date().getFullYear()}
            </HeaderText>
          </Block>
        </FooterRow>
      </FooterColumn>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperProps>`
  background: ${colors.bannerBackground};
  color: ${colors.sekundaer900};
  width: 100%;
  display: column;
  align-self: center;
  border-top: 1rem solid ${colors.primaer900};
  filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25));
  position: ${(props: { position: string }) => props.position};
  bottom: 0;
  padding-bottom: 40px;
`;

const HeaderText = styled.h3`
  font-size: 22px;
`;

const Text = styled.p`
  display: flex;
  font-size: 18px;
`;

const FooterColumn = styled.footer`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 64px;
  justify-content: center;
  @media (max-width: 1000px) {
    padding: 8px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Block = styled.div`
  display: flex;
  > * {
    margin: 8px;
    margin-bottom: 0;
  }
  width: auto;
  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`;

const CustomLink = styled(Link)`
  font-size: 18px;
  color: ${colors.sekundaer900};
  text-decoration: none;
  display: block;
  :hover {
    text-decoration: underline;
  }
`;
