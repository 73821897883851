import { Person } from "../utils/interfaces";

export type boligTekstFormWithOutFiles = {
  name: string;
  userId: string;
  oppdragsnummer: string;
  lokaltStedsnavn: string;
  telefon: string;
  epost: string;
  orgnrMeglerselskap: string;
  status?: ReportStatus;
};

export type uploadResponse = {
  id: string;
};

export type staticContentResponse = {
  boligtype: string[];
  boligstatus: string[];
};

export type userResponseType = {
  user: Person;
};

export type updateStepType = {
  step: number;
  updateStep: (num: number) => void;
  valid?: boolean;
  sendForm?: sendFormType;
  submit?: submitType;
  checkErrors?: () => boolean;
  showPopup?: boolean | undefined;
};

export type submitType = {
  setDownloadLink: (str: string) => void;
  rapportID: string | undefined;
};

export type kvittering = {
  infoNotFiles: boligTekstFormWithOutFiles;
  tilstandsrapportName: string;
  egenerklaeringName: string;
  pictures: string[];
};

export type sendFormType = {
  sendForm: (t: boligTekstFormWithOutFiles) => Promise<string | undefined>;
  updateRapportID: (t: string) => void;
  info: boligTekstFormWithOutFiles;
};

export type SelectedFilesListType = {
  photos?: File[];
  file?: File;
  title: string;
  numberOfPicturesUploaded?: number;
  numberOfPictures?: number;
  fileUploaded?: number;
};

export type ReportType = {
  id: string;
  name: string;
  oppdragsnummer: string;
  adresse: string;
  poststed: string;
  userId: string;
  dato: Date;
  status: ReportStatus;
  lokaltStedsnavn: string;
  prospekt: string;
  boligtype: String;
};

export enum ReportStatus {
  NEW = 0,
  BILLED = 1,
  PAYMENT_RECEIVED = 2,
}
