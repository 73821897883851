import { FC, useEffect } from "react";
import { ToastContainer, toast, ToastContentProps } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { colors } from "../utils/colors";

type OrderConsentProps = {
  setProceed: (proceed: boolean) => void;
};

const cookieBody = (
  props: ToastContentProps,
  setProceed: (proceed: boolean) => void
): JSX.Element => {
  return (
    <Wrapper>
      <TextWrapper>
        <p>
          TXTFLOW er en proptech startup som har som mål å levere tekster av høy
          kvalitet.
        </p>
        <p>
          TXTFLOW er under kontinuerlig utvikling, og nåværende fase er
          tjenesten ment som god hjelp til salgsoppgaven, men det anbefales å
          <b> kontrollere innholdet nøye</b>, samt tilføye egne beskrivelser.
        </p>
        <p>Tilbakemeldinger sendes til hei@txtflow.no. </p>
      </TextWrapper>
      <ConsentButton
        onClick={() => {
          setProceed(true);
          props?.closeToast && props.closeToast();
        }}
      >
        Skjønner!
      </ConsentButton>
    </Wrapper>
  );
};

const OrderConsent: FC<OrderConsentProps> = ({ setProceed }): JSX.Element => {
  useEffect(() => {
    toast((props) => cookieBody(props, setProceed), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      closeButton: true,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }, [setProceed]);

  return <StyledToastContainer />;
};

const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    font-size: 2rem;
    color: black;
    font-family: "Mulish";
    border-radius: 1rem;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ConsentButton = styled.div`
  text-align: center;
  color: ${colors.leftBackground};
  background-color: #49496c;
  font-size: 2rem;
  font-family: "Mulish";
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: none;

  &:hover {
    color: #49496c;
    background-color: ${colors.leftBackground};
    border: 1px solid #49496c;
  }
`;

export default OrderConsent;
