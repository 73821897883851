import axios from "axios";

export enum Environment {
  Localhost,
  Test,
  Production,
  Snap0,
  Snap1,
}

export function resolveEnvironment(): Environment {
  let hostname;
  let env: Environment;
  if (typeof window !== "undefined") {
    hostname = window.location.hostname;
  } else {
    hostname = process.env.CURRENT_ENV;
  }

  if (hostname?.includes("snap0") || process?.env?.CURRENT_ENV === "snap0") {
    env = Environment.Snap0;
  } else if (
    hostname?.includes("snap1") ||
    process?.env?.CURRENT_ENV === "snap1"
  ) {
    env = Environment.Snap1;
  } else if (
    hostname?.endsWith(".test.iterate.no") ||
    process?.env?.CURRENT_ENV === "test"
  ) {
    env = Environment.Test;
  } else if (hostname === "localhost") {
    env = Environment.Localhost;
  } else {
    env = Environment.Production;
  }

  return env;
}

export function getBasePath(): string | undefined {
  const env: Environment = resolveEnvironment();
  switch (env) {
    case Environment.Localhost:
      return "http://localhost:8080";
    case Environment.Test:
      return "https://interpretify-backend.interpretify.test.iterate.no";
    case Environment.Snap0:
      return "https://snap0.interpretify-backend.interpretify.iterate.iterapp.no";
    case Environment.Snap1:
      return "https://snap1.interpretify-backend.interpretify.iterate.iterapp.no";
    case Environment.Production:
      return "https://api.txtflow.no";
    default:
      return;
  }
}

export function getFrontendBasePath(): string | undefined {
  const env = resolveEnvironment();
  switch (env) {
    case Environment.Localhost:
      return "http://localhost:3000";
    case Environment.Test:
      return "https://interpretify-frontend.interpretify.test.iterate.no";
    case Environment.Snap0:
      return "https://snap0.interpretify-frontend.interpretify.iterate.iterapp.no";
    case Environment.Snap1:
      return "https://snap1.interpretify-frontend.interpretify.iterate.iterapp.no";
    case Environment.Production:
      return "https://txtflow.no";
    default:
      return;
  }
}

export function getFrontendDevInfo(): string | undefined {
  const env = resolveEnvironment();
  switch (env) {
    case Environment.Production:
      return "";
    default:
      return "DEV";
  }
}

export const interpretifyApi = axios.create({
  withCredentials: true,
  baseURL: getBasePath(),
});
