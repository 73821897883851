/** @jsxImportSource @emotion/react */
import styled from "styled-components";
import { colors } from "../utils/colors";

type Props = {
  onClick: () => void;
};

export const CancelButton = ({ onClick }: Props) => {
  return <Button onClick={onClick}>Avbryt</Button>;
};

const Button = styled.button`
  box-sizing: border-box;
  background-color: white;
  color: ${colors.delete};
  border: 2px solid ${colors.delete};
  width: 200px;
  height: 40px;
  border-radius: 6px;
  font-weight: bold;
  font-style: normal;
  font-family: Mulish;
  font-size: 2rem;
  margin: 8px;
  cursor: pointer;
`;
