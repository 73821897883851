import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import styled from "styled-components";
import { getOrgInfo, updateUserPersonaliaWithDB } from "../Api";
import BusinessDisplayContainer from "../components/BusinessDisplayContainer";
import CookieConsent from "../components/CookieConsent";
import PersonInfoContainer from "../components/PersonInfoContainer";
import { changeStateString } from "../pages/OrderForm";
import { PaddingTop } from "../styles/styles";
import { UserInfoType } from "../types/enums";
import { colors } from "../utils/colors";
import {
  isNumeric,
  isValidEmail,
  isValidNumber,
  isValidText,
} from "../utils/form_validator";
import { Business } from "../utils/interfaces";
import UserContext from "../utils/UserContext";

const Onboarding: FC<RouteComponentProps> = () => {
  const { userSession, setUserSession } = useContext(UserContext);

  const history = useHistory();

  // org
  const [meglerKontor, setMeglerKontor] = useState<string>(
    userSession.user?.office?.name || ""
  );

  const [validOrgNr, setValidOrgNr] = useState<boolean>(
    isNumeric(meglerKontor)
      ? isValidNumber(meglerKontor, 9)
      : isValidText(meglerKontor)
  );

  const [multipleOrgResults, setMultipleOrgResults] = useState<Array<Business>>(
    []
  );

  const [username, setUsername] = useState<string>(
    userSession.user?.name || ""
  );

  const [validUsername, setValidUsername] = useState<boolean>(
    isValidText(username)
  );

  // Telefonnr
  const [userPhone, setphoneNumber] = useState<string>(
    userSession.user?.phone || ""
  );

  const [validPhone, setValidPhone] = useState<boolean>(
    isValidNumber(userPhone, 8)
  );

  // Fakturaepost
  const [fakturaEmail, setFakturaEmail] = useState<string>(
    userSession.user?.fakturaEmail || ""
  );

  const [validFakturaEmail, setValidFakturaEmail] = useState<boolean>(
    isValidEmail(fakturaEmail)
  );

  // Kontoradresse
  const [adresseKontor, setAdresseKontor] = useState<string>(
    userSession.user?.kontorAdresse || ""
  );

  const [validAdresseKontor, setValidAdresseKontor] = useState<boolean>(
    isValidText(adresseKontor)
  );

  const validateInfo = (
    value: string,
    targetLength?: number,
    type?: UserInfoType
  ) => {
    switch (type) {
      case UserInfoType.PHONE:
        setValidPhone(isValidNumber(value, targetLength));
        break;
      case UserInfoType.ORG:
        if (isNumeric(value)) {
          setValidOrgNr(isValidNumber(value, targetLength));
        } else {
          setValidOrgNr(isValidText(value));
        }
        break;
      case UserInfoType.FAKTURAEMAIL:
        setValidFakturaEmail(isValidEmail(value));
        break;
      case UserInfoType.KONTORADRESSE:
        setValidAdresseKontor(isValidText(value));
        break;
      case UserInfoType.NAME:
        setValidUsername(isValidText(value));
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, [username, setUsername]);

  const setBusiness = (business: Business) => {
    if (userSession.user) {
      let updatedUser = {
        ...userSession.user,
        office: { orgNummer: business.orgNummer, name: business.name },
      };
      setUserSession({
        ...userSession,
        user: updatedUser,
      });
      setMeglerKontor(business.name || "");
      setMultipleOrgResults([]);
    }
  };

  return (
    <Wrapper>
      <PaddingTop padding={"200px"} />
      <Container>
        <Title>
          {userSession.user?.name
            ? "Hei, " +
              userSession.user?.name +
              ". Vennligst fyll inn litt informasjon om deg."
            : "Hei. Vennligst fyll inn litt informasjon om deg."}
        </Title>
        <InfoContainer>
          <PersonInfoContainer
            title={"Bedrift:"}
            value={meglerKontor}
            valid={validOrgNr}
            displayButton={false}
            placeholder={"Skriv inn org.nr. eller bedriftsnavn"}
            onChangeInputElement={(event) => {
              const targetLength: number = isNumeric(meglerKontor) ? 9 : 255;
              if (
                event.target.value.replace(/\s/g, "").length <= targetLength
              ) {
                changeStateString(event, setMeglerKontor);
                validateInfo(
                  event.target.value,
                  targetLength,
                  UserInfoType.ORG
                );
                if (!isNumeric(event.target.value)) {
                  getOrgInfo(event.target.value).then((res) => {
                    let tempList: Array<Business> = [];
                    if (res) {
                      res.forEach(
                        (element: { navn: any; organisasjonsnummer: any }) => {
                          tempList.push({
                            name: element.navn,
                            orgNummer: element.organisasjonsnummer,
                          });
                        }
                      );
                      setMultipleOrgResults(tempList);
                    }
                  });
                } else {
                  setMultipleOrgResults([]);
                  if (isValidNumber(event.target.value, 9)) {
                    getOrgInfo(event.target.value).then((res) => {
                      if (res.length >= 1) {
                        let business: Business = {
                          name: res[0].navn,
                          orgNummer: res[0].organisasjonsnummer,
                        };
                        setBusiness(business);
                      }
                    });
                  }
                }
              }
            }}
          />
          <BusinessResultWrapper>
            {multipleOrgResults.map((business) => {
              return (
                <BusinessDisplayContainer
                  {...business}
                  onClick={() => {
                    setBusiness(business);
                  }}
                />
              );
            })}
          </BusinessResultWrapper>
          <PersonInfoContainer
            title={"Navn:"}
            value={username}
            valid={validUsername}
            displayButton={false}
            placeholder={"Skriv inn navnet ditt"}
            onChangeInputElement={(event) => {
              if (event.target.value.replace(/\s/g, "").length <= 255) {
                changeStateString(event, setUsername);
                validateInfo(event.target.value, 255, UserInfoType.NAME);
                if (userSession.user) {
                  let updatedUser = {
                    ...userSession.user,
                    name: event.target.value,
                  };
                  setUserSession({
                    ...userSession,
                    user: updatedUser,
                  });
                }
              }
            }}
          />
          <PersonInfoContainer
            title={"Kontoradresse:"}
            value={adresseKontor}
            valid={validAdresseKontor}
            displayButton={false}
            placeholder={"Skriv inn kontoradressen"}
            onChangeInputElement={(event) => {
              if (event.target.value.replace(/\s/g, "").length <= 255) {
                changeStateString(event, setAdresseKontor);
                validateInfo(
                  event.target.value,
                  255,
                  UserInfoType.KONTORADRESSE
                );
              }
            }}
          />
          <PersonInfoContainer
            title={"Telefon nr:"}
            value={userPhone}
            valid={validPhone}
            displayButton={false}
            placeholder={"Skriv inn telefon nr"}
            onChangeInputElement={(event) => {
              const targetLength = 8;
              if (
                event.target.value.replace(/\s/g, "").length <= targetLength
              ) {
                changeStateString(event, setphoneNumber);
                validateInfo(
                  event.target.value,
                  targetLength,
                  UserInfoType.PHONE
                );
              }
            }}
          />
          <PersonInfoContainer
            title={"Faktura-epost:"}
            value={fakturaEmail}
            valid={validFakturaEmail}
            displayButton={false}
            placeholder={"Skriv inn faktura-epost"}
            onChangeInputElement={(event) => {
              if (event.target.value.replace(/\s/g, "").length <= 255) {
                changeStateString(event, setFakturaEmail);
                validateInfo(
                  event.target.value,
                  255,
                  UserInfoType.FAKTURAEMAIL
                );
              }
            }}
          />
          <SubmitButton
            valid={
              validOrgNr &&
              validUsername &&
              validAdresseKontor &&
              validPhone &&
              validFakturaEmail
            }
            onClick={() => {
              if (userSession.user) {
                if (
                  validOrgNr &&
                  validUsername &&
                  validAdresseKontor &&
                  validPhone &&
                  validFakturaEmail
                ) {
                  let updatedUser = {
                    ...userSession.user,
                    fakturaEmail: fakturaEmail,
                    phone: userPhone,
                    kontorAdresse: adresseKontor,
                    name: username,
                  };
                  setUserSession({
                    ...userSession,
                    user: updatedUser,
                  });
                  updateUserPersonaliaWithDB(updatedUser);
                  history.push("/");
                }
              }
            }}
          >
            Send inn
          </SubmitButton>
        </InfoContainer>
      </Container>
      <CookieConsent />
    </Wrapper>
  );
};

export default Onboarding;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.sekundaer900};
  min-height: 95vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 3rem;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    text-align: left;
    margin-left: 5%;
  }
`;

const InfoContainer = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
`;

const BusinessResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: 28%;
  @media (max-width: 1000px) {
    margin-left: 10%;
  }
`;

const SubmitButton = styled.button<{ valid: boolean }>`
  width: 265px;
  padding: 12px 16px;
  border: 1px solid ${colors.sekundaer900};
  background-color: ${(props): string =>
    props.valid ? colors.sekundaer900 : colors.sekundaer500};
  color: white;
  border-radius: 8px;
  cursor: ${(props): string => (props.valid ? "pointer" : "default")};
  font-weight: bold;
  font-size: 2rem;
  margin-top: 24px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
