import { FC, useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import CookieConsent from "../components/CookieConsent";
import MyProfileBanner from "../components/MyProfileBanner";
import MyProfileInfo from "../components/MyProfileInfo";
import MyProfileMenu from "../components/MyProfileMenu";
import MyProspects from "../components/MyProspects";
import { InternalMenuRoutes } from "../types/enums";
import UserContext, { AccessControl } from "../utils/UserContext";

const MyProfile: FC<RouteComponentProps> = () => {
  const [currentRoute, setCurrentRoute] = useState<InternalMenuRoutes>(
    InternalMenuRoutes.Informasjon
  );

  const { userSession } = useContext(UserContext);

  AccessControl(userSession);

  return (
    <Wrapper>
      <MyProfileBanner />
      <Container>
        <MyProfileMenu
          currentRoute={currentRoute}
          updateInternalRoute={setCurrentRoute}
        />
        {currentRoute === InternalMenuRoutes.Prospekter && <MyProspects />}
        {currentRoute === InternalMenuRoutes.Informasjon && <MyProfileInfo />}
      </Container>
      <CookieConsent />
    </Wrapper>
  );
};

export default MyProfile;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 120px;
  @media (max-width: 1000px) {
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
`;
