import { FC } from "react";
import styled from "styled-components";
import { InternalMenuRoutes } from "../types/enums";
import { colors } from "../utils/colors";

const MyProfileMenu: FC<{
  currentRoute: InternalMenuRoutes;
  updateInternalRoute: (route: InternalMenuRoutes) => void;
}> = ({ currentRoute, updateInternalRoute }): JSX.Element => {
  const checkPath = (path: InternalMenuRoutes): string => {
    return currentRoute === path ? colors.primaer300 : "none";
  };

  return (
    <Box>
      <LinkButton
        theme={{
          main: checkPath(InternalMenuRoutes.Informasjon),
        }}
        onClick={() => updateInternalRoute(InternalMenuRoutes.Informasjon)}
      >
        Personlig informasjon
      </LinkButton>

      <LinkButton
        theme={{
          main: checkPath(InternalMenuRoutes.Prospekter),
        }}
        onClick={() => updateInternalRoute(InternalMenuRoutes.Prospekter)}
      >
        Mine prospekter
      </LinkButton>
    </Box>
  );
};

export default MyProfileMenu;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Mulish";
  font-size: 1rem;
  width: 20%;
  border: 1px solid ${colors.sekundaer900};
  box-shadow: 0px 0px 4px ${colors.primaer900};
  height: fit-content;
  border-radius: 8px;
  @media (max-width: 1000px) {
    width: 90%;
    flex-direction: row;
    margin-bottom: 2rem;
  }
`;

const LinkButton = styled.div`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:last-child {
    border-bottom: 1px solid #e6e6e6;
  }

  &:hover {
    background-color: ${colors.primaer300};
  }
  transition: all 0.3s ease;

  font-size: 2rem;
  color: #000a61;
  font-weight: normal;
  font-family: "Mulish";
  padding: 2rem 1rem;
  background-color: ${(props) => props.theme.main};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-of-type {
    border-radius: 8px 8px 0 0;
  }
  :last-of-type {
    border-radius: 0 0 8px 8px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    :first-of-type {
      border-radius: 8px 0 0 8px;
    }
    :last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
`;
