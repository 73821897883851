import styled from "@emotion/styled";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { colors } from "../utils/colors";
import Icon from "../utils/Icon";

const PersonInfoContainer: FC<{
  title: string;
  value: string;
  valid?: boolean;
  editable?: boolean;
  displayButton?: boolean;
  placeholder?: string;
  optional?: boolean;
  onChangeInputElement?: (event: ChangeEvent<HTMLInputElement>) => void;
  onLock?: (value: string) => void;
}> = ({
  title,
  value,
  valid = true,
  editable = true,
  displayButton = true,
  optional = false,
  placeholder,
  onChangeInputElement: onChange,
  onLock,
}) => {
  const [editLocked, setEditLocked] = useState(valid);
  const [isValid, setIsValid] = useState(valid);

  useEffect(() => {
    setIsValid(valid);
  }, [editLocked, isValid, title, value, valid]);

  const lockField = () => {
    if (editable && isValid) {
      setEditLocked((state) => !state);
      if (onLock) {
        onLock(value);
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <ContentBox>
          <ValueText
            isValid={isValid}
            editLocked={editLocked}
            value={value}
            onChange={(event) => (onChange ? onChange(event) : () => {})}
            readOnly={editLocked}
            placeholder={placeholder}
            optional={optional}
          />
          {editable && displayButton ? (
            <IconButton
              editLocked={editLocked}
              isValid={isValid}
              optional={optional}
              onClick={() => lockField()}
            >
              <Icon name={editLocked ? "locked" : "unlocked"} />
            </IconButton>
          ) : (
            <Placeholder />
          )}
        </ContentBox>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PersonInfoContainer;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 4rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const Title = styled.div`
  font-weight: 900;
  margin-left: 2rem;
  font-size: 2rem;
  width: 15rem;
  text-align: left;
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 4px;
  }
`;

const ValueText = styled.input<{
  isValid?: boolean;
  editLocked?: boolean;
  optional?: boolean;
}>`
  width: 100%;
  max-width: 60rem;
  margin-left: 2rem;
  font-size: 2rem;
  border: 1px solid ${colors.sekundaer900};
  box-sizing: border-box;
  box-shadow: 0px 0px 4px ${colors.primaer900};
  border-radius: 8px;
  padding: 1rem 2rem;
  display: flex;
  height: 5rem;
  background-color: ${(props): string =>
    props.optional ? "white" : props.isValid ? "white" : colors.ikkeGodkjent};
  :hover {
    cursor: ${(props): string => (props.editLocked ? "not-allowed" : "normal")};
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`;

const IconButton = styled.button<{
  editLocked: boolean;
  isValid?: boolean;
  optional?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.sekundaer900};
  background-color: ${(props): string =>
    props.optional ? "white" : props.isValid ? "white" : colors.ikkeGodkjent};
  border-radius: 8px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-left: 8px;
  font-size: 2rem;
  :hover {
    cursor: ${(props): string => (props.isValid ? "pointer" : "not-allowed")};
  }
  color: ${(props): string => (props.isValid ? "black" : "grey")};
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
`;

const Placeholder = styled.div``;

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;
