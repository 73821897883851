import { ReportType } from "../types/types";
import styled from "styled-components";
import { getBasePath } from "../utils/env";
import { colors } from "../utils/colors";

const ListItem = (report: ReportType): JSX.Element => {
  return (
    <Box key={`box_${report.id}`}>
      <InfoBox key={`infobox_${report.id}`}>
        <HeaderText key={`headertext_${report.id}`}>
          Oppdragsnummer: {report.oppdragsnummer}
        </HeaderText>
        <InfoText key={`infotext_${report.id}`}>
          {report.adresse}, {report.poststed}
        </InfoText>
      </InfoBox>
      <DownloadButton
        key={`downloadbutton_${report.id}`}
        href={`${getBasePath()}/api/v1/rapporter/${report.id}/download`}
      >
        {" "}
        Last ned rapport
      </DownloadButton>
    </Box>
  );
};

export default ListItem;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.sekundaer900};
  box-sizing: border-box;
  border-radius: 6px;
  margin: 1rem;
  padding: 2rem;
  width: 40%;
  color: ${colors.sekundaer900};
  box-shadow: 0 2px 4px #0004;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  margin-left: 1rem;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 1.7rem;
`;
const InfoText = styled.p`
  font-size: 1.7rem;
`;

const DownloadButton = styled.a`
  text-decoration: none;
  box-shadow: 0 2px 4px #0004;
  background-color: ${colors.sekundaer900};
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 1rem;
  color: white;
  padding: 12px 16px;
  border-radius: 40px;
`;
