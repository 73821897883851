import styled from "@emotion/styled";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import CookieConsent from "../components/CookieConsent";
import backgroundImage from "../res/images/Forside.png";
import {
  EmojiSalesPunkt,
  EmojiTekstPunkter,
  frontPageHeaderTitle
} from "../res/infoText";
import { colors } from "../utils/colors";
import ScrollToTop from "../utils/ScrollToTop";
import UserContext from "../utils/UserContext";

const Home = (): JSX.Element => {
  const { userSession } = useContext(UserContext);
  const history = useHistory();
  if (userSession.user) {
    if (!userSession.user.office?.orgNummer) {
      history.push("/onboarding");
    }
  }

  return (
    <Wrapper>
      <ScrollToTop />
      <BgHouseImage src={backgroundImage} alt="Forside bilde" />
      <BodyWrapper>
        <ContentWrapper>
          <TextBox>
            <Title>{frontPageHeaderTitle}</Title>
            <Padding>
              <InfoText>
                <EmojiBoldText>{EmojiSalesPunkt}</EmojiBoldText>
                {EmojiTekstPunkter.map((element) => {
                  return <EmojiText>{element}</EmojiText>;
                })}
              </InfoText>
              <ButtonWrapper>
                <StyledLink to={userSession.user ? "/bestilling" : "/login"}>
                  {userSession.user ? "Kom i gang" : "Prøv gratis"}
                </StyledLink>
                <StyledLink to={"/info"}>{"Mer informasjon"}</StyledLink>
              </ButtonWrapper>
            </Padding>
          </TextBox>
        </ContentWrapper>
      </BodyWrapper>
      <CookieConsent />
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  padding-top: 80px;
  height: auto;
  color: ${colors.sekundaer900};
  font-family: Mulish;
  @media (max-width: 1000px) {
    height: auto;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: fit-content;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: fit-content;
  }
`;

const ContentWrapper = styled.div<{ imageContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    width: 100%;
    display: ${(props) => (props.imageContainer ? "none" : "flex")};
    height: ${(props) => (props.imageContainer ? "0" : "fit-content")};
  }
`;

const BgHouseImage = styled.img`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding: 1rem 0;
  gap: 16px;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  font-size: 2.5rem;
  letter-spacing: 0.1em;
  padding: 1.5rem 3rem;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${colors.leftBackground};
  background-color: ${colors.sekundaer900};
  box-shadow: 0 2px 4px #0004;
  text-decoration: none;
`;

const TextBox = styled.div`
  margin: 128px 64px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid ${colors.sekundaer500};
  box-shadow: 8px 8px 0px ${colors.sekundaer500};
  width: 85%;
  @media (max-width: 1000px) {
    margin: 64px 24px;
  }
`;

const Padding = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1000px) {
    padding: 8px 16px;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 3rem;
  }
`;

const EmojiBoldText = styled.p`
  font-size: 2.1rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
`;

const EmojiText = styled.p`
  font-size: 2rem;
  margin: 0;
  margin-bottom: 8px;
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 1000px) {
    margin: 0 8px;
    width: 90%;
  }
`;
