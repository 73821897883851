import styled from "@emotion/styled";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CookieConsent from "../components/CookieConsent";
import Inputfield from "../components/Inputfield";
import SimpleButton from "../components/SimpleButton";
import { colors } from "../utils/colors";
import {
  getBasePath,
  getFrontendBasePath,
  interpretifyApi,
} from "../utils/env";
import { isValidEmail } from "../utils/form_validator";
import Icon from "../utils/Icon";

interface EmailValidation {
  value: string;
  state: string;
  isValid: boolean;
}

const Login = (): JSX.Element => {
  const basePath = getBasePath();
  const { location } = useHistory();
  const frontendBasePath = getFrontendBasePath();

  let redirect = "";
  if (location.search && location.search.startsWith("?redirect")) {
    redirect = decodeURIComponent(location.search.replace("?redirect=", ""));
  }

  const [emailLogin, setEmailLogin] = useState<EmailValidation>({
    value: "",
    state: "closed",
    isValid: false,
  });

  const submitEmail = async (email: string) => {
    if (!emailLogin.isValid) {
      return;
    }

    await interpretifyApi
      .post("/magic-link", {
        email,
      })
      .then((response) => {
        setEmailLogin((prev) => ({
          ...prev,
          state:
            response.status >= 200 && response.status < 300
              ? "success"
              : "error",
        }));
      })
      .catch((e) => {
        setEmailLogin((prev) => ({ ...prev, state: "error" }));
      });
  };

  return (
    <Wrapper>
      <Body>
        <LoginContainer>
          <LoginLabel> {"Logg inn"}</LoginLabel>

          {emailLogin.state === "open" && (
            <>
              <Inputfield
                title={""}
                type={"text"}
                errorText={""}
                placeholder="Email"
                value={emailLogin.value}
                onChangeInputElement={(event) => {
                  setEmailLogin((prev) => ({
                    ...prev,
                    value: event.target.value,
                    isValid: isValidEmail(event.target.value),
                  }));
                }}
              />
              <LoginButton
                notValidEmail={
                  !emailLogin.isValid && emailLogin.state === "open"
                }
                onClick={() => submitEmail(emailLogin.value)}
              >
                {emailLogin.isValid ? "Send link" : "Skriv in gyldig epost"}
              </LoginButton>
            </>
          )}

          {emailLogin.state === "error" && (
            <>
              <InfoText>Oisann! Noe gikk galt, vennligst prøv igjen.</InfoText>
              <SimpleButton
                text="Back"
                onClick={() =>
                  setEmailLogin((prev) => ({ ...prev, state: "closed" }))
                }
              ></SimpleButton>
            </>
          )}

          {emailLogin.state === "success" && (
            <>
              <InfoText>
                Vi har sendt en mail til {emailLogin.value}.{"\n"} Vennligst
                åpne mailen for å fullføre innloggingen.
              </InfoText>
            </>
          )}

          {emailLogin.state === "closed" && (
            <>
              <LoginButton
                href={`${basePath}/login/GOOGLE?redirect_uri=${frontendBasePath}${redirect}`}
                rel="noopener noreferrer"
              >
                <ButtonText>Google</ButtonText>
                <Icon name="google+" />
              </LoginButton>
              <LoginButton
                href={`${basePath}/login/MICROSOFT?redirect_uri=${frontendBasePath}${redirect}`}
                rel="noopener noreferrer"
              >
                <ButtonText>Microsoft</ButtonText>
                <Icon name="microsoft" />
              </LoginButton>
              <LoginButton
                rel="noopener noreferrer"
                onClick={() =>
                  setEmailLogin((prev) => ({ ...prev, state: "open" }))
                }
              >
                <ButtonText>Email</ButtonText>
              </LoginButton>
            </>
          )}
          <TermsText>
            By signing in you verify that you&apos;ve read and accept the{" "}
            <Link
              to="/"
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontWeight: "bold" }}
            >
              terms of service
            </Link>
          </TermsText>
        </LoginContainer>
      </Body>
      <CookieConsent />
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  min-height: 90vh;
`;

const Body = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  color: ${colors.sekundaer900};
`;

const LoginContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 256px;
`;

const LoginLabel = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  font-family: Mulish;
  font-size: 4rem;
  font-weight: bolder;
`;

const TermsText = styled.div`
  font-size: 2rem;
`;

const LoginButton = styled.a<{ notValidEmail?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid transparent;
  background: none;
  color: ${colors.sekundaer900};
  border: 1px solid currentColor;
  border-radius: 50px;
  :hover,
  :focus {
    cursor: pointer;
  }
  height: 48px;
  min-width: 36px;
  min-height: 36px;
  margin-bottom: 16px;
  font-size: 2rem;
  :last-of-type {
    margin-bottom: 40px;
  }
  text-decoration: none;
  font-weight: bold;
  background-color: ${(props) =>
    props.notValidEmail ? colors.ikkeGodkjent : "white"};
`;

const ButtonText = styled.div`
  margin-right: 8px;
`;

const InfoText = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  color: ${colors.sekundaer900};
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;
