import { SyntheticEvent } from "react";
import styled from "@emotion/styled";
import { colors } from "../utils/colors";
import Icon, { AvailableIcons } from "../utils/Icon";

type Props = {
  onClick?: (args: SyntheticEvent) => void;
  className?: string;
  text: string;
  icon?: AvailableIcons;
  type?: "button" | "reset" | "submit";
};

const SimpleButton = ({
  onClick,
  className,
  text,
  icon,
  type = "button",
}: Props): JSX.Element => {
  return (
    <StyledButton onClick={onClick} className={className} type={type}>
      <LabelWrapper>{text}</LabelWrapper>
      {icon && (
        <IconWrapper>
          <Icon name={icon}></Icon>
        </IconWrapper>
      )}
    </StyledButton>
  );
};

export default SimpleButton;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid transparent;
  background: none;
  color: ${colors.sekundaer900};
  border: 1px solid ${colors.sekundaer900};
  border-radius: 48px;
  min-height: 36px;
  :hover,
  :focus {
    cursor: pointer;
  }
`;

const LabelWrapper = styled.div`
  margin-left: 8px;
  @media (max-width: 500px) {
    max-width: 120px;
    overflow: auto;
    :-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-left: 8px;
  font-size: 2rem;
`;
