/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, ChangeEvent } from "react";
import {
  Form_Label_Style,
  Input_Container_Style,
  Input_TextArea_Style,
} from "../styles/styles";
import { colors } from "../utils/colors";

const Inputfield: FC<{
  title: string;
  value?: string | number;
  onChangeInputElement?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeTextArea?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  type?: string;
  entity?: string;
  textArea?: boolean;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  isProtected?: boolean;
}> = ({
  title,
  value,
  onChangeInputElement: onChange,
  onChangeTextArea,
  type,
  entity,
  placeholder,
  error,
  errorText,
  isProtected = false,
}) => {
  return (
    <div
      css={
        errorText
          ? css`
              margin-bottom: 4px;
            `
          : css`
              margin-bottom: 29px;
              width: 100%;
            `
      }
    >
      <label css={Form_Label_Style}>
        {title}
        {onChange ? (
          <div css={Input_Container_Style}>
            <Input
              readOnly={isProtected}
              disabled={isProtected}
              type={type}
              value={value || ""}
              onChange={(event) => onChange(event)}
              css={Input_TextArea_Style}
              placeholder={placeholder}
              error={error ? "true" : undefined}
            />
            <label>{entity ? entity : ""}</label>
          </div>
        ) : (
          <div
            css={css`
              width: 100%;
            `}
          >
            <textarea
              css={Input_TextArea_Style}
              value={value}
              onChange={(event) => onChangeTextArea && onChangeTextArea(event)}
            />
          </div>
        )}
      </label>
      {!(errorText === "") && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};
export default Inputfield;

const Input = styled.input<{ error?: "true" }>`
  ${Input_TextArea_Style}
  background-color: ${(prop) => (prop.error ? colors.ikkeGodkjent : undefined)};
`;

const ErrorText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 8px;
  align-items: center;
  font-size: 1.3rem;
  font-family: "Mulish";
`;
