import EditIcon from "./icons/EditIcon";
import GoogleIcon from "./icons/Google+Icon";
import LockedIcon from "./icons/LockedIcon";
import LoginIcon from "./icons/LoginIcon";
import MicrosoftIcon from "./icons/MicrosoftIcon";
import UnLockedIcon from "./icons/UnLockedIcon";
import UserIcon from "./icons/UserIcon";

export type AvailableIcons =
  | "user"
  | "login"
  | "microsoft"
  | "google+"
  | "google-drive"
  | "edit"
  | "locked"
  | "unlocked"
  | "google+-with-circle";

type Props = {
  name: AvailableIcons;
  className?: string;
};

const Icon = ({ name, className }: Props): JSX.Element => {
  switch (name) {
    case "user":
      return <UserIcon />;
    case "login":
      return <LoginIcon />;
    case "google+":
      return <GoogleIcon />;
    case "microsoft":
      return <MicrosoftIcon />;
    case "edit":
      return <EditIcon />;
    case "locked":
      return <LockedIcon />;
    case "unlocked":
      return <UnLockedIcon />;
    default:
      return <svg></svg>;
  }
};

export default Icon;
