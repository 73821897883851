import styled from "@emotion/styled";
import { FC } from "react";
import { colors } from "../utils/colors";

const BusinessDisplayContainer: FC<{
  name: string;
  orgNummer: string;
  onClick: () => void;
}> = ({ name, orgNummer, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <NameBox>{name}</NameBox>
      <OrgBox>{orgNummer}</OrgBox>
    </Wrapper>
  );
};

export default BusinessDisplayContainer;

const Wrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  margin-bottom: 1rem;
  border: 1px solid ${colors.sekundaer500};
  box-sizing: border-box;
  margin-left: -2rem;
  border-radius: 8px;
  padding: 1rem 2rem;
  background-color: ${colors.primaer300};
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

const NameBox = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  max-width: 44rem;
`;

const OrgBox = styled.div`
  font-size: 2rem;
`;
