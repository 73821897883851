import { createContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Person } from './interfaces';

export type UserSession = {
    user: Person | undefined;
    loaded: boolean; 
    
};

const UserContext = createContext<{
    userSession: UserSession;
    setUserSession: (userSession: UserSession) => void;
}>({
    userSession: {
        loaded: false,
        user: undefined,
        
    },
    setUserSession: (userSession: UserSession) => {
        return;
    },
});

export default UserContext;


export const AccessControl =(session:UserSession, checkAdmin:Boolean = false) => {
    const history = useHistory();
    useEffect(() => {
      
      if (session.loaded) {
        if (!session.user) {
          history.push("/login");
        } else {
          if (checkAdmin && !session.user.isAdmin) {
            history.push("/");
          }
        }
      }
    }, [session,checkAdmin,history]);
}