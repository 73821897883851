import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../utils/colors";
import { form_properies } from "../utils/properties_css";

export const Input_TextArea_Style = css`
  border: 1px solid ${colors.sekundaer900};
  box-sizing: border-box;
  box-shadow: 0px 0px 4px ${colors.primaer900};
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 8px;
`;

export const Input_Container_Style =css`
display: flex;
flex-direction: row;
align-items: flex-end;
font-size: 2rem;
width: 100%;
`

export const Input_Content_Style = css`
display: flex;
flex-direction: column;
align-items: flex-start;
font-size: 2rem;
width: 100%;
`

export const Form_Container_Style = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    width: ${form_properies.form_width};
  }
  width: ${form_properies.form_width_mobile};
`;
export const Bottom_Buttons_Container_Style = css`
  display: flex;
  flex-direction: row;
  @media (min-width: 1000px) {
    width: ${form_properies.form_width};
  }
  width: ${form_properies.form_width_mobile};
  justify-content: space-between;
  padding: 30px;
`;

export const Form_Label_Style = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2rem;
  color: ${colors.sekundaer900};
`;

export const Drop_Zone_Container_Style = css`
display: flex;
flex-direction: column;
@media (min-width: 1000px) {
  flex-direction: row;
  align-items: flex-start;
}
min-height: 395px;
width: 80%;
min-width: 500px;
justify-content: space-around;
align-items: center;
`
export const Drop_Down_Container_Style = css`
  display: flex;
  align-content: center;
  margin-bottom: 16px;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const PaddingTop = styled.div<{
  padding: string;
}>`
  padding-top: ${(props): string => props.padding};
`;

export const HeaderPadding = styled.div`
height: 80px;
`;
