export const placeholder_navn = "Fornavn Etternavn";
export const placeholder_telefon = "8 siffere";
export const placeholder_ePost = "din@epost.no";
export const placeholder_orgnr = "9 siffere";
export const placeholder_oppdragsnummer = "Oppdragsnummer";
export const placeholder_adresse = "Gate nr";
export const placeholder_stedsnavn = "Område";
export const placeholder_pris = "Pris";
export const placeholder_boligtype = "...";
export const placeholder_boligstatus = "...";
