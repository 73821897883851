/* eslint-disable import/no-webpack-loader-syntax */
import styled from "@emotion/styled";
import { lazy, Suspense } from "react";

const Content = lazy(
  () => import("!babel-loader!mdx-loader!./md/PrivacyPolicy.mdx")
);

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Body>
        <Suspense fallback={<div>Laster...</div>}>
          <Content />
        </Suspense>
      </Body>
    </Wrapper>
  );
};

export default PrivacyPolicy;

const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    height: auto;
  }
`;

const Body = styled.div`
  max-width: 700px;
  margin: 11rem auto;
  padding: 0 16px;
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    margin: 1rem 0;
    font-size: 16px;
  }
  ul {
    list-style-type: disc;
    margin: 1em 0 1em 16px;
    font-size: 16px;
  }
  li {
    margin-bottom: 8px;
  }
`;
