export enum InternalMenuRoutes {
  Prospekter,
  Informasjon,
}

export enum UserInfoType {
  PHONE,
  ORG,
  FAKTURAEMAIL,
  KONTORADRESSE,
  NAME,
}
