import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { Link, Route } from "react-router-dom";
import { logoutUser } from "../Api";
import { colors } from "../utils/colors";
import UserContext, { UserSession } from "../utils/UserContext";
import SimpleButton from "./SimpleButton";
import OutsideClickHandler from "react-outside-click-handler";
import { getFrontendDevInfo } from "../utils/env";

const Header = (): JSX.Element => {
  const { userSession, setUserSession } = useContext(UserContext);
  const [dropdownIsOpen, setDropdonwIsOpen] = useState(false);

  const SignOutUser = async (setUserSession: (props: UserSession) => void) => {
    await logoutUser().then((success) => {
      if (success) {
        setUserSession({
          loaded: false,
          user: undefined,
        });
      }
    });
  };

  return (
    <Background>
      <StyledLink
        to="/"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Logo>TXTFLOW</Logo>
      </StyledLink>
      <DevLogo>{getFrontendDevInfo()}</DevLogo>
      <HeaderRightItemsContainer>
        {userSession.user ? (
          <UserWrapper>
            <OutsideClickHandler
              onOutsideClick={() => setDropdonwIsOpen(false)}
            >
              <SimpleButton
                text={userSession.user.email}
                icon={"user"}
                onClick={() => setDropdonwIsOpen((state) => !state)}
              />
              <Route
                render={({ history }) => (
                  <Dropdown isOpen={dropdownIsOpen}>
                    <TextButton
                      onClick={() => {
                        history.push("/min_profil");
                        setDropdonwIsOpen(false);
                      }}
                    >
                      Min Profil
                    </TextButton>
                    {userSession.user?.isAdmin && (
                      <TextButton
                        onClick={() => {
                          history.push("/admin");
                          setDropdonwIsOpen(false);
                        }}
                      >
                        Admin-panel
                      </TextButton>
                    )}
                    <TextButton
                      onClick={() => {
                        history.push("/bestilling");
                        setDropdonwIsOpen(false);
                      }}
                    >
                      Nytt skjema
                    </TextButton>
                    <TextButton
                      onClick={() => {
                        // pusher til avsnitt over priser pga fixed header
                        history.push(
                          "/terms-and-conditions#36-konsekvenser-av-misbruk"
                        );
                        history.go(0); // refresher siden
                        setDropdonwIsOpen(false);
                      }}
                    >
                      Priser
                    </TextButton>
                    <TextButton
                      onClick={() => {
                        SignOutUser(setUserSession).then(() =>
                          history.push("/")
                        );
                      }}
                    >
                      Logg ut
                    </TextButton>
                  </Dropdown>
                )}
              />
            </OutsideClickHandler>
          </UserWrapper>
        ) : (
          <Route
            render={({ history }) => (
              <SimpleButton
                text={"Logg inn"}
                icon={"login"}
                onClick={() => {
                  history.push("/login");
                }}
              />
            )}
          />
        )}
      </HeaderRightItemsContainer>
    </Background>
  );
};

export default Header;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-bottom: 1rem solid ${colors.primaer900};
  filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25));
`;

const Logo = styled.div`
  position: absolute;
  left: 4rem;
  font-size: 3rem;
  font-weight: 1000;
  letter-spacing: 0.75rem;
  color: ${colors.sekundaer900};
  @media (max-width: 500px) {
    font-size: 2rem;
  }
`;

const HeaderRightItemsContainer = styled.div`
  position: absolute;
  right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  min-width: 2rem;
`;

const Dropdown = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 110%;
  right: 0;
  padding: ${(props) => (props.isOpen ? "6px 12px 12px 6px" : "0px")};
  min-width: 80px;
  max-width: 96vw;
  background: white;
  color: ${colors.sekundaer500};
  overflow: hidden;
  display: flex;
  box-shadow: 0 2px 4px #0004;
  flex: 1;
  flex-direction: column;
  align-content: center;
  max-height: ${(props): string => (props.isOpen ? "360px" : "0px")};
  border-radius: 12px;
  > * {
    width: 100%;
    text-align: right;
    padding: 8px;
  }
`;

const TextButton = styled.button`
  text-decoration: none;
  background-color: transparent;
  color: ${colors.sekundaer900};
  border: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  :last-child {
    font-weight: bold;
  }
`;

const UserWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const DevLogo = styled.div`
  position: absolute;
  left: 190px;
  font-size: 12px;
  font-style: italic;
  @media (max-width: 500px) {
    left: 4rem;
    bottom: 1rem;
    font-size: 8px;
  }
`;
