/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";

const ProgressBar : FC<{
    bgcolor: string,
    completed: number
}>= ({ bgcolor, completed }) => {

  return (
    <div 
    css={css`
        height: 20;
        width: 80%;
        background-color: #F4F4F4;
        border-radius: 6px;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}>
      <div css={css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: ${completed}%;
        background-color:  ${bgcolor};
        border-radius: inherit;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: right;
        transition: width 1s ease-in-out;
      `}>
        <span css={css`
        padding-right: 5px;
        color: white;
        font-weight: bold;
        font-family: 'Mulish';
        `}>{`${completed.toPrecision(3)}%`}</span>
      </div>
    </div>
  );
};
export default ProgressBar;