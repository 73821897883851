/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DragEvent, FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { SelectedFileList } from "./SelectedFileList";

export type DropZoneProps = {
  pathAfterPublic: string;
  selectedFiles: File[] | File | undefined;
  multiple?: boolean;
  validTypes: string[];
  setSelectedFiles?: (file: File[]) => void;
  setSelectedFile?: (file: File) => void;
  title: string;
  setNumberOfPictures?: (n: number) => void;
  numberOfPictures?: number;
  numberOfPicturesUploaded?: number;
  fileUploaded?: number;
  errorText?: string;
};

// https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
const DropZone: FC<DropZoneProps> = ({
  pathAfterPublic,
  selectedFiles,
  setSelectedFiles,
  setNumberOfPictures,
  validTypes,
  multiple,
  setSelectedFile,
  title,
  numberOfPictures,
  numberOfPicturesUploaded,
  fileUploaded,
  errorText = "",
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer!!.files;
    if (files.length && setSelectedFiles) {
      handleFiles(files);
    } else if (files.length && setSelectedFile) {
      handleFile(files[0]);
    }
  };
  const handleFiles = (files: FileList) => {
    if (setSelectedFiles) {
      let list: File[] = [...(selectedFiles as File[])];
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          list.push(files[i]);
        }
      }
      setSelectedFiles(list);
      setNumberOfPictures!!(list.length);
    }
  };
  const handleFile = (file: File) => {
    if (setSelectedFile) {
      setSelectedFile(file);
    }
  };
  const validateFile = (file: File) => {
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };
  const fileInputClicked = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current !== null) {
      if (fileInputRef.current.files!!.length && setSelectedFiles) {
        handleFiles(fileInputRef.current.files!!);
      } else if (fileInputRef.current.files!!.length && setSelectedFile) {
        handleFile(fileInputRef.current.files!![0]);
      }
    }
  };

  const pdfUploaded = () =>
    errorText !== "" ? false : !Array.isArray(selectedFiles) && selectedFiles;

  useEffect(() => {}, [errorText]);

  return (
    <Wrapper>
      <div
        onDragOver={pdfUploaded() ? undefined : dragOver}
        onDragEnter={pdfUploaded() ? undefined : dragEnter}
        onDragLeave={pdfUploaded() ? undefined : dragLeave}
        onDrop={pdfUploaded() ? undefined : fileDrop}
        onClick={pdfUploaded() ? undefined : fileInputClicked}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 24px;
          margin: 16px;
          width: 80%;
          max-height: 256px;
          cursor: pointer;
        `}
      >
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          multiple={multiple}
          accept={validTypes
            .map((it) => it.split("/"))
            .map((it) => "." + it[1])
            .join(", ")}
          onChange={filesSelected}
          css={css`
            display: none;
          `}
        />
        <img
          css={css`
            max-height: 200px;
            height: 100%;
            width: 100%;
            opacity: ${errorText !== ""
              ? "100%"
              : !Array.isArray(selectedFiles) && selectedFiles
              ? "50%"
              : "100%"};
            z-index: -1;
          `}
          src={process.env.PUBLIC_URL + pathAfterPublic}
          alt=""
        />
      </div>
      {errorText === "" ? (
        <SelectedFileList
          photos={Array.isArray(selectedFiles) ? selectedFiles : undefined}
          file={Array.isArray(selectedFiles) ? undefined : selectedFiles}
          title={title}
          numberOfPictures={numberOfPictures}
          numberOfPicturesUploaded={numberOfPicturesUploaded}
          fileUploaded={fileUploaded}
        />
      ) : (
        <ErrorText>{errorText}</ErrorText>
      )}
    </Wrapper>
  );
};

export default DropZone;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
  @media (min-width: 1000px) {
    padding-bottom: 24px;
  }
`;

const ErrorText = styled.div`
  font-size: 2rem;
  color: red;
  max-width: 80%;
`;
