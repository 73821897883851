import { useEffect, useState } from "react";
import { ToastContainer, toast, ToastContentProps } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { colors } from "../utils/colors";

const daysConsented = 365;

enum Consent {
  CONSENT,
  ASK,
  NONE,
}

const cookieBody = (
  props: ToastContentProps,
  setConsent: (val: Consent) => void
): JSX.Element => {
  return (
    <Wrapper>
      <TextWrapper>
        <Image
          src={`${process.env.PUBLIC_URL}/images/meglermann.png`}
          alt="Coffee-cup"
        />
        <p>Denne nettsiden bruker cookies for å forbedre din opplevelse.</p>
      </TextWrapper>
      <ConsentButton
        onClick={() => {
          setConsent(Consent.CONSENT);
          setConsentCookie(true);
          props?.closeToast && props.closeToast();
        }}
      >
        Skjønner!
      </ConsentButton>
    </Wrapper>
  );
};

function setConsentCookie(consent: boolean) {
  const now = new Date();
  let expires = now.getTime() + daysConsented * 24 * 60 * 60 * 1000;
  let expiration_date = new Date(0);
  expiration_date.setUTCMilliseconds(expires);
  document.cookie = `consented=${consent}; secure; domain=${window.location.hostname}; expires=${expiration_date}; samesite=strict;`;
}

const CookieConsent = (): JSX.Element => {
  const [consent, setConsent] = useState<Consent>(Consent.ASK);

  useEffect(() => {
    if (document.cookie.indexOf("consented=true") > -1) {
      setConsent(Consent.CONSENT);
    } else {
      setConsent(Consent.ASK);
    }
  }, []);

  useEffect(() => {
    toast((props) => cookieBody(props, setConsent), {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false,
      closeButton: true,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }, []);

  return consent === Consent.ASK ? <StyledToastContainer /> : <div></div>;
};

const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    font-size: 2rem;
    color: black;
    font-family: "Mulish";
    border-radius: 1rem;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Image = styled.img`
  flex: 1;
  width: 50px;
  height: null;
  resize: contain;
  margin-right: 10px;
`;

const ConsentButton = styled.div`
  text-align: center;
  color: ${colors.leftBackground};
  background-color: #49496c;
  font-size: 2rem;
  font-family: "Mulish";
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: none;

  &:hover {
    color: #49496c;
    background-color: ${colors.leftBackground};
    border: 1px solid #49496c;
  }
`;

export default CookieConsent;
